import { selectDishTypes } from "slices/dishesSlice";

import { useAppSelector } from "hooks/utilsHooks/storeHooks";
import { LocalDishType } from "types/mainTypes";

export const useDishTypes = (): { data: LocalDishType[] } => {
	const dishTypes = useAppSelector(selectDishTypes) || [];
	return {
		data: dishTypes,
	};
};

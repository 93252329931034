import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";

import { Order, Refill, TransactionState } from "types";

type TransactionSlice = {
	order: Order | null;
	refill: Refill | null;
	currentOrderStep: number;
};

const initialState: TransactionSlice = {
	order: null,
	refill: null,
	currentOrderStep: 1,
};

export const transactionSlice = createSlice({
	name: "transaction",
	initialState,
	reducers: {
		setOrder: (state, { payload }: PayloadAction<Order | null>) => {
			state.order = payload;
		},
		setRefill: (state, { payload }: PayloadAction<Refill | null>) => {
			state.refill = payload;
		},
		updateCurrentOrderStep: (state, action: PayloadAction<number>) => {
			state.currentOrderStep = action.payload;
		},
		finishOrderState: (state) => {
			state.order = null;
			state.currentOrderStep = 1;
		},
	},
});

export const { finishOrderState, setOrder, updateCurrentOrderStep } = transactionSlice.actions;
export const selectOrder = (state: RootState) => state.transaction?.order || null;
export const selectUserPreferences = (state: RootState) =>
	state.transaction?.order?.user?.preferences || null;
export const selectOrderState = (state: RootState) => {
	return state.transaction.order?.state || null;
};
export const selectCurrentOrderStep = (state: RootState) => {
	return state.transaction.currentOrderStep;
};
export const selectOrderDishes = createSelector(
	selectOrder,
	(order) => order?.inventory?.removed || [],
);
export const selectIsEmptyOrder = (state: RootState) => {
	const order = state.transaction.order;
	if (!order) {
		return false;
	}
	const orderHasEmptyInventory = order.inventory?.removed?.length === 0 || !order.inventory;
	return orderHasEmptyInventory && order.state === TransactionState.Finished;
};

// TODO: add fetching logic for when there are unknown dish types in the order
export const selectOrderDishTypes = createSelector(
	[(state: RootState) => state.dishes.types, selectOrder],
	(fridgeDishes, order) => {
		if (fridgeDishes) {
			if (!order?.inventory?.removed) {
				return [];
			}
			const orderDishes = order.inventory.removed;
			const orderDishTypeIds = new Set(orderDishes.map((dish) => dish?.type?.id));
			return fridgeDishes?.filter((dishType) => orderDishTypeIds.has(dishType.id));
		}
		return [];
	},
);
export const selectOrderSummary = createSelector(
	selectOrder,
	selectOrderDishTypes,
	(order, orderDishTypes) => {
		if (!order) {
			return null;
		}
		return {
			orderDishTypes: orderDishTypes || [],
			orderPrice: order?.price,
		};
	},
);

export const selectOrderPaymentMethod = createSelector(
	selectOrder,
	(order) => order?.payment?.method?.type || null,
);

export default transactionSlice.reducer;

import { FC, PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { selectAppState } from "slices/appStateSlice";

import { SnackbarContent } from "components/global/snackbar/SnackbarContent";
import { useOrderSteps } from "hooks/useOrderSteps";
import { useAppSelector } from "hooks/utilsHooks/storeHooks";
import { TransactionState } from "types";
import { AppState } from "types/mainTypes";

import { selectCurrentOrderStep, selectOrder, selectOrderDishes } from "../slices/transactionSlice";
import { useModalContext } from "./ModalContext";
import { useSnackbarContext } from "./SnackbarContext";

type DrawerContextProps = {
	open: boolean;
	position?: "left" | "right";
	toggleDrawer: (open: boolean) => void;
	currentOrderStepScreen: JSX.Element;
	currentOrderStep: number;
};

export const DrawerContext = createContext<DrawerContextProps>({
	open: false,
	position: "left",
	toggleDrawer: () => null,
	currentOrderStepScreen: <></>,
	currentOrderStep: 1,
});

export const DrawerContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const { setModal } = useModalContext();
	const { modal } = useModalContext();
	const order = useAppSelector(selectOrder);
	const orderDishes = useAppSelector(selectOrderDishes);
	const currentOrderStep = useAppSelector(selectCurrentOrderStep);
	const [isOpen, setIsOpen] = useState(
		order != null && order.state !== TransactionState.Finished,
	);
	const currentOrderStepScreen = useOrderSteps({
		open: isOpen,
		toggleDrawer: setIsOpen,
	});
	const { openSnackbarWithContent } = useSnackbarContext();
	const appState = useAppSelector(selectAppState);
	const handleSetOrderStep1 = () => {
		setIsOpen(true);
		setModal(null);
	};
	const handleSetOrderStep2 = () => {
		const userAllergens = order?.user?.preferences?.allergens ?? false;
		const userHasPreselectedAllergens = userAllergens && userAllergens?.length > 0;
		setIsOpen(true);
		setModal(null);
		openSnackbarWithContent(
			<SnackbarContent
				variant="welcome"
				user={order?.user}
				subheader={
					userHasPreselectedAllergens
						? "Your dietary restriction filter were applied"
						: undefined
				}
			/>,
		);
	};

	const handleSetOrderStep3 = () => {
		setIsOpen(true);
		setModal(null);
	};

	const handleSetOrderStep5 = () => {
		setModal(null);
		if (!isOpen) {
			setIsOpen(true);
		}
		if (orderDishes.length === 0) {
			openSnackbarWithContent(<SnackbarContent variant="emptyOrder" user={order?.user} />);
		} else {
			openSnackbarWithContent(<SnackbarContent variant="orderFinished" user={order?.user} />);
		}
	};

	const shouldResetOrder1State = () => {
		return (
			appState === AppState.IDLE &&
			(!order || order?.state === TransactionState.Finished) &&
			currentOrderStep === 1 &&
			!isOpen
		);
	};

	useEffect(() => {
		if (shouldResetOrder1State()) {
			setIsOpen(true);
		}
	}, [appState]);

	useEffect(() => {
		switch (currentOrderStep) {
			case 1:
				handleSetOrderStep1();
				break;
			case 2:
				handleSetOrderStep2();
				break;
			case 3:
				handleSetOrderStep3();
				break;
			case 4:
				setModal(null);
				if (!isOpen) {
					setIsOpen(true);
				}
				break;
			case 5:
				handleSetOrderStep5();
				break;
			default:
				setModal(null);
				if (!isOpen) {
					setIsOpen(true);
				}
		}
	}, [currentOrderStep]);

	useEffect(() => {
		if (modal) {
			setIsOpen(false);
		}
	}, [modal]);

	return (
		<DrawerContext.Provider
			value={{
				open: isOpen,
				toggleDrawer: setIsOpen,
				currentOrderStep,
				currentOrderStepScreen,
			}}
		>
			{children}
		</DrawerContext.Provider>
	);
};

export const useDrawerContext = () => {
	return useContext(DrawerContext);
};

import { Chip, ChipProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Allergen } from "utils/AllergensMapping";

const StyledChip = styled(Chip)(({ theme }) => ({
	backgroundColor: "rgba(255, 255, 255, 0.1)",
	color: theme.palette.common.white,
	padding: "8px 16px 8px 8px",
	fontSize: "1.125rem",
	fontWeight: 500,
	svg: {
		"& path": {
			fill: theme.palette.copper.main,
			color: theme.palette.copper.main,
		},

		width: "1.5rem",
		height: "1.5rem",
	},
}));

export const AllergenChip = ({ allergen, ...rest }: ChipProps & { allergen: Allergen }) => {
	const { t } = useTranslation();
	return (
		<StyledChip icon={<allergen.icon />} label={t(`allergens.${allergen.name}`)} {...rest} />
	);
};

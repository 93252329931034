import { Snackbar } from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import {
	FC,
	PropsWithChildren,
	ReactElement,
	createContext,
	useContext,
	useEffect,
	useState,
} from "react";

function SlideTransition(props: PropsWithChildren<SlideProps>) {
	return (
		<Slide {...props} direction="down">
			{props.children}
		</Slide>
	);
}

export type SnackbarContextType = {
	openSnackbarWithContent: (content: ReactElement) => void;
};

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
	padding: "20px 40px",
	width: "95%",
	margin: "auto",
	backgroundColor: theme.palette.common.white,
	borderRadius: theme.shape.borderRadius,
}));

export const SnackbarContext = createContext<SnackbarContextType>({} as SnackbarContextType);

export const SnackbarContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const [open, setOpen] = useState(false);
	const [snackbarQueue, setSnackbarQueue] = useState<readonly ReactElement[]>([]);
	const [snackbarContent, setSnackbarContent] = useState<ReactElement | undefined>(undefined);

	useEffect(() => {
		if (snackbarQueue.length && !snackbarContent) {
			// Set a new snack when we don't have an active one
			setSnackbarContent({ ...snackbarQueue[0] });
			setSnackbarQueue((prev) => prev.slice(1));
			setOpen(true);
		} else if (snackbarQueue.length && snackbarContent && open) {
			// Close an active snack when a new one is added
			setOpen(false);
		}
	}, [snackbarQueue, snackbarContent, open]);

	const openSnackbarWithContent = (content: ReactElement) => {
		setSnackbarQueue((prev) => [...prev, content]);
	};

	const handleClose = () => {
		setOpen(false);
		setSnackbarContent(undefined);
	};

	const handleExited = () => {
		setSnackbarContent(undefined);
	};

	return (
		<SnackbarContext.Provider value={{ openSnackbarWithContent }}>
			<StyledSnackbar
				key={snackbarContent?.props.variant}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				autoHideDuration={5000}
				open={open}
				onClose={handleClose}
				TransitionComponent={SlideTransition}
				TransitionProps={{ onExited: handleExited }}
			>
				{snackbarContent}
			</StyledSnackbar>
			{children}
		</SnackbarContext.Provider>
	);
};

export const useSnackbarContext = () => {
	return useContext(SnackbarContext);
};

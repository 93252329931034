import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { isArray } from "lodash";
import type { RootState } from "store";
import { filterAvailableDishTypes, sortDishTypes } from "utils/mealListUtils";
import { getAvailableDishTypeIds, mergeDishTypes } from "utils/utils";

import { LocalDishType, LocalFridgeDishes } from "types/mainTypes";

import { selectFilters, selectSelectedLanguage } from "./appStateSlice";
import { selectOrder, selectOrderDishes } from "./transactionSlice";

type DishesSlice = LocalFridgeDishes;

const initialState: DishesSlice = {
	types: [],
	items: [],
};

export const dishesSlice = createSlice({
	name: "dishes",
	initialState,
	reducers: {
		setInitialDishTypes: (state, action) => {
			state.types = action.payload;
		},
		setDishTypes: (
			state,
			{ payload }: PayloadAction<{ dishTypes: LocalDishType[] | null; isOrder?: boolean }>,
		) => {
			const { dishTypes: updatedDishTypes, isOrder } = payload;

			if (!state.types) {
				if (updatedDishTypes) {
					state.types = updatedDishTypes;
				}
			} else if (isArray(updatedDishTypes)) {
				const updatedDishTypesIds = updatedDishTypes?.map((dishType) => dishType.id);

				// Update local dishes based on dishes array in WS message
				// Only update dish type dishes when the message is related to order
				if (isOrder) {
					const cleanedDishTypes = state.types?.map((dishType) => {
						const stateDishTypeShouldHaveEmptyDishes =
							dishType.dishes &&
							dishType.dishes?.length > 0 &&
							!updatedDishTypesIds?.includes(dishType.id);
						/* If local dish type has dishes, but it is not in updated dishes array it
							means, that last dish of that dish type was taken and we should set
							its dishes to empty array
						*/
						if (stateDishTypeShouldHaveEmptyDishes) {
							return {
								...dishType,
								dishes: [],
							};
							/*
							 * If given dish type is in updated dishes array,
							 * return update dish type from updated dishes array
							 * */
						} else if (updatedDishTypesIds?.includes(dishType.id)) {
							return updatedDishTypes.find(
								(updatedDishType) => updatedDishType.id === dishType.id,
							);
						}

						/*
						 * else return original dish type
						 * */
						return dishType;
					});
					state.types = [...mergeDishTypes(state.types, cleanedDishTypes)];
				} else {
					state.types = [...mergeDishTypes(state.types, updatedDishTypes)];
				}
			} else {
				if (updatedDishTypes) {
					state.types = [...mergeDishTypes(state.types, updatedDishTypes)];
				}
			}
		},
	},
});

export const { setDishTypes, setInitialDishTypes } = dishesSlice.actions;
export const selectDishTypes = (state: RootState): LocalDishType[] => state.dishes.types || [];
export const selectAvailableDishTypesIds = createSelector(
	selectDishTypes,
	selectOrder,
	selectOrderDishes,
	(fridgeDishTypes, order, orderDishes) => {
		if (!fridgeDishTypes) {
			return new Set<string>();
		}
		return getAvailableDishTypeIds(fridgeDishTypes, orderDishes, order?.state);
	},
);
export const selectIsFridgeEmpty = createSelector(
	selectDishTypes,
	selectOrder,
	(fridgeDishTypes, order) => {
		const hasDishTypeWithDishes =
			fridgeDishTypes.length > 0 &&
			fridgeDishTypes.some((dishType) => Boolean(dishType.dishes?.length));

		return !order && !hasDishTypeWithDishes;
	},
);
export const selectAvailableDishTypes = createSelector(
	selectDishTypes,
	selectAvailableDishTypesIds,
	selectFilters,
	selectSelectedLanguage,
	(fridgeDishTypes, availableDishTypeIds, filters, selectedLanguage) => {
		const filteredDishes = filterAvailableDishTypes(
			fridgeDishTypes,
			availableDishTypeIds,
			filters.allergens,
			true,
		);
		return sortDishTypes(filteredDishes, selectedLanguage);
	},
);
export const selectDishTypesNotIncludedInFilters = createSelector(
	selectDishTypes,
	selectAvailableDishTypesIds,
	selectFilters,
	selectSelectedLanguage,
	(fridgeDishTypes, availableDishTypeIds, filters, selectedLanguage) => {
		const filteredDishes = filterAvailableDishTypes(
			fridgeDishTypes,
			availableDishTypeIds,
			filters.allergens,
			false,
		);
		return sortDishTypes(filteredDishes, selectedLanguage);
	},
);
export default dishesSlice.reducer;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
	CurrencyType,
	DishSubscription,
	FridgeTransactionSubscription,
	PaymentMethodType,
	TransactionState,
} from "../../../../src/gql/graphql";
import { WSDataVariant } from "../types";

const finishedTransactionBase: FridgeTransactionSubscription = {
	orders: {
		last: {
			id: "b87d1af6-55e6-4109-88bc-038de9e36cf2",
			type: null,
			state: TransactionState.Finished,
			user: {
				id: "874e7eeb-06bd-4eb5-8f3f-9e34b71ac205",
				name: "Playwright User",
				__typename: "UserSubscription",
			},
			inventory: {
				removed: [],
				added: null,
				updated: null,
				__typename: "TransactionInventorySubscription",
			},
			payment: null,
			price: {
				total: {
					default: "0",
					current: "0",
					currency: CurrencyType.Czk,
					__typename: "PriceSubscription",
				},
				__typename: "OrderPriceSubscription",
			},
			__typename: "OrderSubscription",
		},
		__typename: "OrdersSubscription",
	},
	__typename: "FridgeTransactionSubscription",
};

export const removedMealsSmallOrder: DishSubscription[] = [
	{
		id: "556baf7b-cdbe-4915-96ef-acce10336e0a",
		type: {
			id: "f903f2b0-eac0-11ee-ba26-017635aabc68",
			__typename: "DishTypeSubscription",
		},
		__typename: "DishSubscription",
	},
];

export const removedMealsLargeOrder: DishSubscription[] = [
	{
		id: "8be5407c-87da-46c1-8697-fda8a2a167aa",
		type: {
			id: "238a0040-eab9-11ee-8a1c-dd53dca93003",
			__typename: "DishTypeSubscription",
		},
		__typename: "DishSubscription",
	},
	{
		id: "5ae77dd6-f081-431d-9e0a-505324823601",
		type: {
			id: "ae61c870-eab8-11ee-99b6-433b6e81eff6",
			__typename: "DishTypeSubscription",
		},
		__typename: "DishSubscription",
	},
	{
		id: "9b3dbb9c-e204-4ce2-958b-09ef5e691bfc",
		type: {
			id: "3b7846f0-eac1-11ee-b188-19458354045c",
			__typename: "DishTypeSubscription",
		},
		__typename: "DishSubscription",
	},
	{
		id: "5f36481e-31a5-49ec-8454-e0666c19813d",
		type: {
			id: "bf5d3080-eac0-11ee-920d-fd2633fcf0d2",
			__typename: "DishTypeSubscription",
		},
		__typename: "DishSubscription",
	},
	{
		id: "58bcacba-f2cc-458e-9fc9-004d8961a323",
		type: {
			id: "f903f2b0-eac0-11ee-ba26-017635aabc68",
			__typename: "DishTypeSubscription",
		},
		__typename: "DishSubscription",
	},
	{
		id: "bc83b040-10bb-41fc-b3da-b97a552be20f",
		type: {
			id: "787782f0-eac1-11ee-b4a2-658b668005c2",
			__typename: "DishTypeSubscription",
		},
		__typename: "DishSubscription",
	},
	{
		id: "69c451c5-7342-4b3c-a2e3-e0daed9a10c4",
		type: {
			id: "70d4aab0-eac0-11ee-a82f-ddc9e37a1c29",
			__typename: "DishTypeSubscription",
		},
		__typename: "DishSubscription",
	},
];

export const getFinishedTransaction = (variant?: WSDataVariant) => {
	const data: FridgeTransactionSubscription = { ...finishedTransactionBase };
	switch (variant) {
		case WSDataVariant.LARGE_ORDER:
			data.orders.last.inventory.removed = [...removedMealsLargeOrder];
			data.orders.last.price.total.default = "721";
			data.orders.last.price.total.current = "721";
			data.orders.last.payment = {
				method: {
					id: "f903f2b0-eac0-11ee-ba26-017635aabc68",
					__typename: "PaymentMethodSubscription",
					type: PaymentMethodType.EmployeeCard,
				},
			};
			break;
		case WSDataVariant.NATIVE_PAYMENT:
			data.orders.last.inventory.removed = [...removedMealsSmallOrder];
			data.orders.last.price.total.default = "159";
			data.orders.last.price.total.current = "159";
			data.orders.last.payment = {
				method: {
					id: "f903f2b0-eac0-11ee-ba26-017635aabc68",
					__typename: "PaymentMethodSubscription",
					type: PaymentMethodType.NativePay,
				},
			};
			break;
		case WSDataVariant.SMALL_ORDER:
			data.orders.last.price.total.default = "159";
			data.orders.last.price.total.current = "159";
			data.orders.last.inventory.removed = [...removedMealsSmallOrder];
			data.orders.last.payment = {
				method: {
					id: "f903f2b0-eac0-11ee-ba26-017635aabc68",
					__typename: "PaymentMethodSubscription",
					type: PaymentMethodType.EmployeeCard,
				},
			};
			break;
		case WSDataVariant.EMPTY:
		default:
			break;
	}
	return data;
};

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Config } from "services/configuration/eatSmartConfig";

import { BasicModal } from "components/global/modals/BasicModal";
import { useFilters } from "hooks/useFilters";
import { AllergenType } from "types";

import { FiltersModal } from "./FilterModal";
import { FilterButton, HighlightedAllergens, SelectedFilters } from "./components";

/**
 * Quick filters component
 * @constructor
 */
export const QuickFilters = () => {
	const { setFilters, allergens } = useFilters();
	const { highlightedAllergens } = Config;

	const handleFilterChange = (checkState: string | boolean, filterValue: AllergenType) => {
		if (checkState) {
			setFilters({ allergens: [...allergens, filterValue] });
		} else {
			setFilters({ allergens: allergens.filter((allergen) => allergen !== filterValue) });
		}
	};

	return (
		<QuickFiltersWrapper gap={2}>
			<Box display={"flex"} alignItems={"center"} width={"100%"}>
				<BasicModal
					toggleButton={<FilterButton allergens={allergens} />}
					modalContent={<FiltersModal />}
					closable={true}
					fullHeight={true}
				/>
				<HighlightedAllergens
					allergens={allergens}
					handleFilterChange={handleFilterChange}
				/>
			</Box>
			<SelectedFilters
				allergens={allergens.filter((allergen) => !highlightedAllergens.includes(allergen))}
				handleFilterChange={handleFilterChange}
			/>
		</QuickFiltersWrapper>
	);
};

const QuickFiltersWrapper = styled(Box)(() => ({
	justifyContent: "flex-start",
	alignItems: "center",
	display: "flex",
	flexWrap: "wrap",
	width: "100%",
}));

export enum WSDataType {
	ACTIVE_TRANSACTION,
	DOOR_UNLOCKED,
	DOOR_LOCKED,
	DOOR_OPENED,
	DISH_REMOVED,
	DOOR_CLOSED,
	FINISHED_TRANSACTION,
	QR_CODE,
}

export enum WSDataVariant {
	EMPTY,
	SMALL_ORDER,
	LARGE_ORDER,
	NATIVE_PAYMENT,
	EMPTY_ORDER_START,
}

export type WSMessage = { type: WSDataType; variant?: WSDataVariant };

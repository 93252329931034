import { Box, Button, Chip, ChipProps, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import parse from "html-react-parser";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AllergensMapping } from "utils/AllergensMapping";

import { useFilters } from "hooks/useFilters";
import { AllergenType } from "types";
import { AppState, ModalContentProps } from "types/mainTypes";

import { useAppSelector } from "../../hooks/utilsHooks/storeHooks";
import { selectAppState } from "../../slices/appStateSlice";

export const FiltersModal = ({ onClose }: ModalContentProps) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const { allergens, setFilters } = useFilters();
	const appState = useAppSelector(selectAppState);

	const handleFilterClick = (allergen: AllergenType) => {
		if (allergens.includes(allergen)) {
			setFilters({ allergens: allergens.filter((a) => a !== allergen) });
		} else {
			setFilters({ allergens: [...allergens, allergen] });
		}
	};

	const handleClose = () => {
		// The tracking will be handled by the useFilters hook
		if (onClose) {
			onClose();
		}
	};

	// Close modal when tablet is idle
	useEffect(() => {
		if (appState === AppState.IDLE) {
			handleClose();
		}
	}, [appState]);

	return (
		<StyledFilterModal>
			<StyledFilterModalSection>
				<Typography variant={"promoHeader"} sx={{ mb: 0 }}>
					{t("filters.greeting")}
				</Typography>
				<Typography variant={"promoText"} sx={{ mb: 4 }}>
					{parse(t("filters.filterDescription"))}
				</Typography>
				{AllergensMapping.map((allergen) => (
					<StyledFilterChip
						icon={<allergen.icon />}
						size={"medium"}
						key={`filters_${allergen.name}`}
						label={t(`allergens-free.${allergen.name}`)}
						onClick={() => handleFilterClick(allergen.name)}
						isFiltered={allergens.includes(allergen.name)}
					/>
				))}
			</StyledFilterModalSection>

			<StyledModalButtonBar>
				<StyledButton
					sx={{
						backgroundColor: theme.palette.darkBackground.default,
						color: theme.palette.common.black,
					}}
					disabled={allergens.length === 0}
					onClick={() => setFilters({ allergens: [] }, true)}
				>
					<Typography variant={"button"}>{t("filters.resetButton")}</Typography>
				</StyledButton>
				<StyledButton
					sx={{
						backgroundColor: theme.palette.common.black,
						color: theme.palette.common.white,
					}}
					onClick={() => handleClose()}
				>
					<Typography variant={"button"}>{t("filters.filterButton")}</Typography>
				</StyledButton>
			</StyledModalButtonBar>
		</StyledFilterModal>
	);
};

const StyledButton = styled(Button, {
	name: "FilterModalActionButton",
})(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	padding: "16px 24px",
}));

const StyledFilterModal = styled(Box, {
	name: "StyledFilterModal",
})(() => ({
	padding: "40px",
	height: "100%",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
}));

const StyledFilterModalSection = styled(Box, {
	name: "StyledFilterModalSection",
})(({ theme }) => ({
	display: "flex",
	gap: theme.spacing(1),
	flexWrap: "wrap",
}));

const StyledFilterChip = styled(Chip, {
	shouldForwardProp: (propName: PropertyKey) => propName !== "isFiltered",
})<ChipProps & { isFiltered: boolean }>(({ theme, isFiltered }) => ({
	height: "3rem",
	borderRadius: "80px",
	span: {
		padding: 0,
		fontWeight: 600,
		fontSize: "1.5rem",
		lineHeight: "1.75rem",
	},
	padding: "8px 20px 8px 20px",

	"& svg path": {
		fill: theme.palette.copper.main,
	},

	"&.MuiChip-root": {
		flexFlow: "row-reverse",
		...(isFiltered && {
			backgroundColor: "black",
			color: "white",
		}),
	},
}));

const StyledModalButtonBar = styled(Box)(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-end",
	alignItems: "center",
	width: "100%",
	marginTop: "auto",
	gap: theme.spacing(2),
}));

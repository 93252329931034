import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ElementType } from "react";

interface FilterChipLabelProps {
	label: string;
	isFiltered?: boolean;
	icon?: ElementType;
}

export function FilterChipLabel({ label, isFiltered, icon }: FilterChipLabelProps) {
	const theme = useTheme();
	return (
		<StyledFilterChipLabel isFiltered={isFiltered}>
			<Typography variant={"filter"}>{label}</Typography>
			<StyledIcon
				component={icon}
				sx={{
					fill: theme.palette.copper.main,
				}}
			/>
		</StyledFilterChipLabel>
	);
}

const StyledIcon = styled(Box)(({ theme }) => ({
	marginLeft: theme.spacing(1),
	borderRadius: "50%",
}));

export const StyledFilterChipLabel = styled(Box, {
	name: "StyledFilterChipLabel",
	shouldForwardProp: (propName: PropertyKey) => propName !== "isFiltered",
})<BoxProps & { isFiltered?: boolean }>(({ theme, isFiltered }) => ({
	display: "flex",
	alignItems: "center",
	color: isFiltered ? theme.palette.common.black : theme.palette.common.white,
	borderRadius: "80px",
	marginLeft: 0,
	padding: 0,
	...(isFiltered && {
		"& svg": {
			padding: 0,
		},
	}),
	...((!isFiltered || false) && {
		"& svg": {
			padding: theme.spacing(0.5),
		},
		"& svg path": {
			fill: theme.palette.copper.main,
		},
	}),
}));

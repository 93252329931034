import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { changeLanguage } from "i18next";
import { setFilters, setLocale, setSelectedLanguage } from "slices/appStateSlice";
import { setOrder } from "slices/transactionSlice";
import { getAnalyticsInstance } from "utils/analytics/analyticsUtils";
import { log } from "utils/logger";

import { AllergenType, Maybe } from "types";
import { AppLanguage } from "types/mainTypes";
import { MixpanelActionEventsEnum } from "types/mixpanelTypes";

const mapLanguage = (language?: string) => {
	if (language !== "cs" && language !== "sk") {
		return {
			language: AppLanguage.SECONDARY,
			locale: "en-GB",
		};
	}
	return {
		language: AppLanguage.PRIMARY,
		locale: "cs-CZ",
	};
};

const handlePreselectedLanguageSwitch = (store: MiddlewareAPI, language: string) => {
	const mappedLanguage = mapLanguage(language);
	const analytics = getAnalyticsInstance();

	store.dispatch(setSelectedLanguage(mappedLanguage.language));
	changeLanguage(mappedLanguage.locale).catch((e) => {
		log(`Could not change locale ${e}`, "warn");
	});
	analytics.track(MixpanelActionEventsEnum.Tablet_Language_Changed, {
		$language: language,
		$trigger_source: "user-preferences",
	});
	store.dispatch(setLocale(mappedLanguage.locale));
};

const handlePreselectedFilters = (store: MiddlewareAPI, allergens: Maybe<AllergenType[]>) => {
	const analytics = getAnalyticsInstance();

	if (allergens && allergens.length > 0) {
		const currentAllergens = store.getState().appState.filters.allergens || [];
		// We want to combine allergens that were selected wit the ones from preferences
		const filteredAllergens = allergens.filter((a) => !currentAllergens.includes(a));
		const appliedFilters = [...currentAllergens, ...filteredAllergens];
		analytics.track(MixpanelActionEventsEnum.Tablet_Filter_Applied, {
			$filters: appliedFilters,
			$filter_count: appliedFilters.length,
			$trigger_source: "auto-user-preferences",
		});
		store.dispatch(
			setFilters({
				allergens: appliedFilters,
			}),
		);
	}
};

export const userPreferencesMiddleware: Middleware = (store) => (next) => (action) => {
	const result = next(action);

	if (!action.payload?.user?.preferences) {
		return result;
	}
	if (setOrder.match(action) && action.payload.user.preferences.language) {
		const { language, allergens } = action.payload.user.preferences;
		handlePreselectedLanguageSwitch(store, language);
		handlePreselectedFilters(store, allergens);
	}

	return result;
};

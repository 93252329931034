import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";
import mixpanel, { Dict } from "mixpanel-browser";
import { FC, PropsWithChildren, createContext, useContext, useEffect, useRef } from "react";
import { Config } from "services/configuration/eatSmartConfig";
import { sendCloudwatchMetric } from "services/external/cloudwatch";
import { selectAppState } from "slices/appStateSlice";
import { selectOrder } from "slices/transactionSlice";
import { setAnalyticsInstance } from "utils/analytics/analyticsUtils";
import { shouldTrackCloudwatch } from "utils/analytics/cloudwatch";
import { canSentEventsToAnalyticsTools } from "utils/analytics/mixpanel";
import { getFridgeId } from "utils/browser/localStorage";
import { log } from "utils/logger";
import { v4 as uuid } from "uuid";

import { useAppSelector } from "hooks/utilsHooks/storeHooks";
import { AnalyticsContextProps, AppState } from "types/mainTypes";
import { MixpanelTabletTypeEnum } from "types/mixpanelTypes";

/**
 * Represents the context for analytics.
 **/
export const AnalyticsContext = createContext<AnalyticsContextProps>({
	track: () => null,
	reset: () => null,
	identify: () => null,
	people: {
		set: () => null,
	},
});

const CLOUDWATCH_UPTIME_CHECK_INTERVAL = 30000;

/**
 * AnalyticsContextProvider component
 */
export const AnalyticsContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const mixpanelInstance = mixpanel;
	const customerIoInstance = new AnalyticsBrowser();
	const fridgeId = getFridgeId();
	const appState = useAppSelector(selectAppState);
	const order = useAppSelector(selectOrder);
	const initializedRef = useRef(false);

	// Initialize Mixpanel
	mixpanelInstance.init(Config.tools.mixpanelProjectId, {
		property_blacklist: [
			"$city",
			"$region",
			"mp_country_code",
			"$browser",
			"$browser_version",
			"$device",
			"$current_url",
			"$initial_referrer",
			"$initial_referring_domain",
			"$os",
			"mp_lib",
			"$referrer",
			"$referring_domain",
			"$screen_height",
			"$screen_width",
			"$search_engine",
			"mp_keyword",
		],
	});

	// Initialize Customer.io
	if (Config.tools.customerIoWriteKey) {
		customerIoInstance
			.load({
				cdnURL: "https://cdp-eu.customer.io",
				writeKey: Config.tools.customerIoWriteKey,
			})
			.catch((e) => {
				log("Error while initializing Customer.io", "error", e);
			});
	}

	useEffect(() => {
		if (initializedRef.current) return; // guard clause
		initializedRef.current = true;

		const commonProperties = {
			$session_id: uuid(),
			$source_of_stats: "Tablet",
			$tabletType: MixpanelTabletTypeEnum.Master,
			$fridge_id: fridgeId,
		};

		mixpanelInstance.register(commonProperties);

		if (Config.tools.customerIoWriteKey) {
			customerIoInstance.identify(undefined, {
				device_id: fridgeId,
			});
		}

		setAnalyticsInstance({
			track: actions.track,
			reset: actions.reset,
			identify: actions.identify,
			people: actions.people,
		});
	}, []);

	const actions = {
		track: (name: string, props: Dict) => {
			const useAnalytics = canSentEventsToAnalyticsTools();

			if (useAnalytics) {
				mixpanelInstance.track(name, {
					...props,
				});
				customerIoInstance
					.track(name, {
						...props,
						$source_of_stats: "Tablet",
						$tabletType: MixpanelTabletTypeEnum.Master,
						$fridge_id: fridgeId,
					})
					.catch(() => {
						log("Error while sending the track event to customer.io", "error");
					});
			}

			if (!useAnalytics) {
				// eslint-disable-next-line no-console
				log("Not master tablet, not tracking", undefined, name, props);
			}
		},
		reset: () => {
			const commonProperties = {
				$session_id: uuid(),
				$source_of_stats: "Tablet",
				$tabletType: MixpanelTabletTypeEnum.Master,
				$fridge_id: fridgeId,
			};

			// Reset Mixpanel
			mixpanelInstance.reset();
			mixpanelInstance.register(commonProperties);

			// Reset Customer.io
			if (Config.tools.customerIoWriteKey) {
				customerIoInstance.reset();
			}
		},
		identify: (userId: string, userData?: { [key: string]: string | number }) => {
			// Identify user in Mixpanel
			mixpanelInstance.identify(userId);

			// Identify user in Customer.io with anonymous ID (sessionId)
			// This allows CustomerIO to associate previous anonymous events
			// from this session with the identified user
			const traits = {
				...userData,
				// Keep fridgeId as device_id for device tracking
				device_id: fridgeId,
			};
			customerIoInstance.identify(userId, traits);
		},
		people: {
			set: (data: { [key: string]: string | number }) => {
				// Set user properties in Mixpanel
				mixpanelInstance.people.set(data);
			},
		},
	};

	useEffect(() => {
		let interval = null;
		const shouldTrackToCloudwatch = shouldTrackCloudwatch();
		interval = setInterval(() => {
			sendCloudwatchMetric(shouldTrackToCloudwatch);
		}, CLOUDWATCH_UPTIME_CHECK_INTERVAL);

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, []);

	useEffect(() => {
		if (appState === AppState.IDLE && !order) {
			actions.reset();
		}
	}, [appState]);

	return (
		<AnalyticsContext.Provider
			value={{
				track: actions.track,
				reset: actions.reset,
				identify: actions.identify,
				people: actions.people,
			}}
		>
			{children}
		</AnalyticsContext.Provider>
	);
};

/**
 * Retrieves the analytics context from the React Context API.
 *
 * @return {Object} The analytics context.
 */
export const useAnalyticsContext = () => {
	return useContext(AnalyticsContext);
};

import { Box, BoxProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";

const FridgePositionRow = ({
	placedMeals,
	rowIndex,
}: {
	placedMeals: (boolean | number)[];
	rowIndex: number;
}) => {
	return (
		<>
			{placedMeals.map((placedMeal, index) => {
				const hasDish = typeof placedMeal === "number";
				return (
					<StyledFridgePosition
						hasMeals={hasDish}
						key={`placedMealRow_${rowIndex}_${index}`}
					>
						<Typography color={"white"} textAlign={"center"}>
							{hasDish ? `${placedMeal}` : "-"}
							{hasDish && <>&times;</>}
						</Typography>
					</StyledFridgePosition>
				);
			})}
		</>
	);
};

export function DishTypeFridgePositionMap(props: { parsedSegments: Array<boolean | number>[] }) {
	return (
		<Box display={"flex"} flexDirection={"column"} gap={1} mt={1}>
			{props.parsedSegments.map((row, index) => (
				<Box
					key={index}
					display={"flex"}
					width={"100%"}
					alignItems={"center"}
					justifyContent={"space-between"}
				>
					<Box display={"flex"} gap={1} width={"90%"} justifyContent={"space-between"}>
						<FridgePositionRow placedMeals={row} rowIndex={index} />
					</Box>
				</Box>
			))}
		</Box>
	);
}

const StyledFridgePosition = styled(Box, {
	shouldForwardProp: (prop) => prop !== "hasMeals",
})<BoxProps & { hasMeals: boolean }>(({ theme, hasMeals }) => ({
	borderRadius: "4px",
	border: "2px solid rgba(255, 255, 255, 0.25)",
	width: "90%",

	...(hasMeals && {
		backgroundColor: theme.palette.common.white,
		p: {
			color: theme.palette.common.black,
			textAlign: "center",
			fontWeight: 600,
		},
	}),
}));

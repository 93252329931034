import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { batch } from "react-redux";
import { setQrCode } from "slices/accessSlice";
import { setInitialDishTypes } from "slices/dishesSlice";
import { setDoorLockState } from "slices/doorSlice";
import { setFridgeId, setSegments } from "slices/fridgeInfoSlice";
import { setLightingMode } from "slices/fridgeLightingSlice";
import { selectOrder, setOrder } from "slices/transactionSlice";
import { AppDispatch } from "store";
import { setFridgeNameToLocalStorage } from "utils/browser/localStorage";
import { INITIAL_STATE_QUERY } from "utils/gqlOperations";
import { deepOmit } from "utils/utils";

import { useAppDispatch, useAppSelector } from "hooks/utilsHooks/storeHooks";
import { Fridge, Order } from "types";
import { LocalFridgeState } from "types/mainTypes";

export const handleSetInitialData = (
	data: Fridge | LocalFridgeState,
	appDispatch: AppDispatch,
	order?: Order | null,
) => {
	const cleanedData = deepOmit(data, ["__typename"]) as LocalFridgeState;
	setFridgeNameToLocalStorage(cleanedData.name);
	batch(() => {
		appDispatch(setFridgeId(cleanedData.id));
		if (cleanedData.access?.qrCode?.code) {
			appDispatch(setQrCode(cleanedData.access.qrCode.code));
		}
		appDispatch(setInitialDishTypes(cleanedData.dishes?.types));
		if (cleanedData.segments) {
			appDispatch(setSegments(cleanedData.segments));
		}
		appDispatch(setDoorLockState(cleanedData.door));
		if (cleanedData.lighting?.mode) {
			appDispatch(setLightingMode(cleanedData.lighting.mode));
		}
		if (cleanedData.transaction?.orders?.last) {
			appDispatch(setOrder(cleanedData.transaction.orders.last));
		}
		if (cleanedData.transaction?.orders?.last) {
			appDispatch(setOrder(cleanedData.transaction.orders.last));
		}
		if (order && order.state && !cleanedData.transaction) {
			appDispatch(setOrder(null));
		}
	});
};

type Props = {
	fridgeId: string;
};

export const useInitialStateQuery = ({ fridgeId }: Props) => {
	const appDispatch = useAppDispatch();
	const order = useAppSelector(selectOrder);

	const { data, loading, refetch } = useQuery(INITIAL_STATE_QUERY, {
		variables: { id: fridgeId },
		fetchPolicy: "network-only",
		refetchWritePolicy: "overwrite",
		onCompleted: ({ fridge }) => {
			handleSetInitialData(fridge, appDispatch, order);
		},
	});

	useEffect(() => {
		if (data?.fridge?.error && data.fridge.error.type === "UNAUTHORIZED") {
			localStorage.removeItem("fridgeId");
			const searchParams = new URLSearchParams(window.location.search);
			searchParams.delete("fridgeId");
			window.location.search = searchParams.toString();
		}
	}, [data?.fridge?.error]);

	return {
		loading,
		refetch,
		data,
	};
};

// Mixpanel events are not in camel case and that is expected, therefore we disable camelcase rule

/* eslint-disable camelcase */
import { Order } from "gql/graphql";

import { useAnalyticsContext } from "contexts/AnalyticsContext";
import { AllergenType, DoorState, OrderFeedbackRatingType, OrderFeedbackReasonType } from "types";
import { LocalDishType, OrderSummary } from "types/mainTypes";
import {
	MixpanelActionEventsEnum,
	MixpanelTabletFieldsEnum,
	MixpanelTabletFiltersFieldsEnum,
	TabletDishDetailSectionEnum,
	TabletTutorialStepEnum,
} from "types/mixpanelTypes";

export const useMixpanelTrackers = () => {
	const analyticsContext = useAnalyticsContext();

	function trackFinishedOrder(
		transactionId: string,
		order: Order | null,
		orderSummary: OrderSummary | null,
	) {
		if (!order || !orderSummary) {
			return;
		}

		const defaultPrice = orderSummary.orderPrice?.total?.default ?? null;
		const currentPrice = orderSummary.orderPrice?.total?.current ?? null;

		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Order_Finished, {
			$order_id: transactionId,
			$number_of_taken_meals: order.inventory?.removed?.length,
			...(defaultPrice && { $order_total_default_price: defaultPrice }),
			...(currentPrice && { $order_total_current_price: currentPrice }),
			$order_currency: orderSummary.orderPrice?.total?.currency,
			$order_payment_method: order.payment?.method?.type,
			$order_type: order.type,
		});
	}

	function trackFinishedEmptyOrder(transactionId: string) {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Order_Finished_Nothing_taken, {
			$order_id: transactionId,
		});
	}

	function trackFinishedEmptyOrderFeedback(feedbackValue: OrderFeedbackReasonType) {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Feedback_Nothing_Taken, {
			[MixpanelTabletFieldsEnum.Tablet_Feedback_Nothing_Taken_value]: feedbackValue,
		});
	}

	function trackFinishedOrderScreen(isEmptyOrder: boolean) {
		analyticsContext.track(
			isEmptyOrder
				? MixpanelActionEventsEnum.Tablet_Open_Nothing_Taken
				: MixpanelActionEventsEnum.Tablet_Open_Purchase_Completed,
			{},
		);
	}

	function trackStockCheckStarted() {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Open_Scanning_The_Fridge, {});
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Scanning_Started, {});
	}

	function trackStockCheckEnded() {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Scanning_Ended, {});
	}

	function trackDoorStateChange(doorState: DoorState) {
		analyticsContext.track(
			doorState === DoorState.Opened
				? MixpanelActionEventsEnum.Tablet_Door_Opened
				: MixpanelActionEventsEnum.Tablet_Door_Closed,
			{},
		);
	}

	function trackOrderStart(order: Order | null) {
		if (order && order.user?.id) {
			analyticsContext.identify(order.user.id, {
				name: order.user.name ?? "",
				user_id: order.user.id,
			});
			analyticsContext.people.set({
				$name: order.user.name,
				$user_id: order.user.id,
			});
			analyticsContext.track(MixpanelActionEventsEnum.Tablet_Open_The_Fridge, {});
			analyticsContext.track(MixpanelActionEventsEnum.Tablet_Order_Started, {
				$order_id: order.id,
				$user_id: order.user.id,
			});
		}
	}

	function trackDoorNotOpened() {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Door_Not_Opened_In_Time, {});
	}

	function trackOpenDishItemDetail(
		isOpen: undefined | boolean,
		dishType: LocalDishType,
		name: {
			header: string | undefined;
			subHeader: string | undefined;
		} | null,
	) {
		if (!isOpen) {
			analyticsContext.track(MixpanelActionEventsEnum.Tablet_Open_Detail_Of_Dish, {
				$tablet_dish_detail_section: TabletDishDetailSectionEnum.About,
				$dish_type_id: dishType.id,
				$dish_type_name: name?.header,
			});
		} else {
			analyticsContext.track(MixpanelActionEventsEnum.Tablet_Close_Detail_Of_Dish, {
				$dish_type_id: dishType.id,
				$dish_type_name: name?.header,
			});
		}
	}

	function trackDishTypeDetailTabSwitch(newValue: number, dishType: LocalDishType) {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Open_Detail_Of_Dish, {
			$tablet_dish_detail_section:
				newValue === 0
					? TabletDishDetailSectionEnum.About
					: newValue === 1
					? TabletDishDetailSectionEnum.Ingredients
					: TabletDishDetailSectionEnum.Instructions,
			$dish_type_name: dishType.description?.primary?.name,
			$dish_type_id: dishType.id,
		});
	}

	function trackOrderStep1Screen() {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Open_Place_Your_Card, {});
	}

	function trackOrderStep3Screen() {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Open_Take_Your_Dishes, {});
	}

	/**
	 * Tracks the tutorial step for a tablet tutorial screens using the provided analytics context.
	 *
	 * @param {number} step - The current step in the tutorial.
	 * tutorial step with.
	 */
	function trackTutorialStep(step: number) {
		let tutorialStep: TabletTutorialStepEnum;
		switch (step) {
			case 0:
				tutorialStep = TabletTutorialStepEnum.ScanTheQRCode;
				break;
			case 1:
				tutorialStep = TabletTutorialStepEnum.OpenTheFridge;
				break;
			case 2:
				tutorialStep = TabletTutorialStepEnum.TakeYourMeal;
				break;
			case 3:
				tutorialStep = TabletTutorialStepEnum.PurchaseIsFinalised;
				break;
			default:
				tutorialStep = TabletTutorialStepEnum.OpenTheFridge;
		}

		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Open_Tutorial, {
			$tutorial_step: tutorialStep,
		});
	}

	function trackDownloadAppBannerClose() {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Close_Banner_Download_app, {});
	}

	function trackDownloadAppBannerClick() {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Click_Banner_Download_app, {});
	}

	function trackFeedbackPurchaseCompleted(value: OrderFeedbackRatingType) {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Feedback_Purchase_Completed, {
			[MixpanelTabletFieldsEnum.Tablet_Feedback_Purchase_Completed_Value]: value,
		});
	}

	function trackOpenReportAnIssueModal() {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Open_Report_Issue_Modal, {});
	}

	function trackReportIssueFeedback(value: OrderFeedbackReasonType) {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Feedback_Report_An_Issue, {
			[MixpanelTabletFieldsEnum.Tablet_Feedback_Report_Issue_Value]: value,
		});
	}

	function trackManualFilterApplied(filters: AllergenType[]) {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Filter_Applied, {
			[MixpanelTabletFiltersFieldsEnum.Tablet_Filters_Value]: filters,
			[MixpanelTabletFiltersFieldsEnum.Tablet_Filters_Count_Value]: filters.length,
			[MixpanelTabletFiltersFieldsEnum.Tablet_Filters_Trigger_Source_Value]: "manual",
		});
	}
	function trackFilterWindowOpen() {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Open_Filter_Window, {});
	}

	function trackTabletLanguageChange(
		language: string,
		triggerSource: "manual" | "user-preference",
	) {
		analyticsContext.track(MixpanelActionEventsEnum.Tablet_Language_Changed, {
			$language: language,
			$trigger_source: triggerSource,
		});
	}

	return {
		trackDishTypeDetailTabSwitch,
		trackDoorNotOpened,
		trackDoorStateChange,
		trackDownloadAppBannerClick,
		trackDownloadAppBannerClose,
		trackFeedbackPurchaseCompleted,
		trackFinishedOrder,
		trackFinishedEmptyOrder,
		trackFinishedEmptyOrderFeedback,
		trackFinishedOrderScreen,
		trackOpenDishItemDetail,
		trackOpenReportAnIssueModal,
		trackOrderStart,
		trackOrderStep1Screen,
		trackOrderStep3Screen,
		trackReportIssueFeedback,
		trackStockCheckEnded,
		trackStockCheckStarted,
		trackTabletLanguageChange,
		trackTutorialStep,
		trackManualFilterApplied,
		trackFilterWindowOpen,
	};
};

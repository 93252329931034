import { Checkbox } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Config } from "services/configuration/eatSmartConfig";
import { AllergensMapping } from "utils/AllergensMapping";

import { AllergenType } from "types";

import { FilterChipLabel } from "./FilterChipLabel";
import { StyledFormControlLabel } from "./StyledFilterControlLabel";

const { highlightedAllergens } = Config;

export const HighlightedAllergens = ({
	allergens,
	handleFilterChange,
}: {
	allergens: string[];
	handleFilterChange: (checkState: string | boolean, filterValue: AllergenType) => void;
}) => {
	const { t } = useTranslation();
	const mappedAllergens = highlightedAllergens.map((filter) => ({
		filter: filter,
		isFiltered: allergens.includes(filter),
	}));

	return (
		<>
			{mappedAllergens.map(({ filter, isFiltered }) => (
				<StyledFormControlLabel
					key={`quickFilter_${filter}`}
					control={<Checkbox />}
					checked={isFiltered}
					onChange={(e, checked) => handleFilterChange(checked, filter)}
					isFiltered={isFiltered}
					label={
						<FilterChipLabel
							label={t(`allergens-free.${filter}`)}
							isFiltered={isFiltered}
							icon={
								AllergensMapping.find((allergen) => allergen.name === filter)?.icon
							}
						/>
					}
				/>
			))}
		</>
	);
};

import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionButton } from "components/primitives/ActionBarButton";

type BackToHomeButtonProps = { finishOrder: () => void };

/**
 * Timer button, that redirects user from Order step 5 to Order step 1 after
 * purchase is completed
 * @param finishOrder
 * @constructor
 */
export const BackToHomeButton = ({ finishOrder }: BackToHomeButtonProps) => {
	const [countdown, setCountdown] = useState(10);
	const { t } = useTranslation();

	useEffect(() => {
		const timer = setInterval(() => {
			if (countdown > 0) {
				setCountdown((prevState) => prevState - 1);
			}
		}, 1000);
		return () => clearTimeout(timer);
	}, []);
	if (countdown > 0) {
		return (
			<ActionButton onClick={() => finishOrder()} stretch={true}>
				<Typography
					variant={"h5"}
					color={"white"}
					fontWeight={900}
					textTransform={"uppercase"}
				>
					{t("order.step5.backToHome", { countdown })}
				</Typography>
			</ActionButton>
		);
	}
	return null;
};

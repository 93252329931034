import { configureStore } from "@reduxjs/toolkit";
import accessReducer from "slices/accessSlice";
import appStateReducer from "slices/appStateSlice";
import dishesReducer from "slices/dishesSlice";
import doorReducer from "slices/doorSlice";
import fridgeInfoReducer from "slices/fridgeInfoSlice";
import fridgeLightingReducer from "slices/fridgeLightingSlice";
import transactionReducer from "slices/transactionSlice";

import { userPreferencesMiddleware } from "./slices/middleware/userPreferencesMiddleware";

export const store = configureStore({
	reducer: {
		access: accessReducer,
		appState: appStateReducer,
		dishes: dishesReducer,
		door: doorReducer,
		transaction: transactionReducer,
		fridgeInfo: fridgeInfoReducer,
		lighting: fridgeLightingReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(userPreferencesMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

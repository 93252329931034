import { Box, Button, Typography, styled } from "@mui/material";
import React, { RefObject, useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
	resetAppState,
	selectAppState,
	selectHowToPanelVisibility,
	selectOpenedDetailDishTypeId,
	setHowToBannerVisibility,
} from "slices/appStateSlice";
import {
	selectAvailableDishTypes,
	selectAvailableDishTypesIds,
	selectDishTypes,
	selectDishTypesNotIncludedInFilters,
} from "slices/dishesSlice";
import { selectOrder } from "slices/transactionSlice";
import { getSpotlightDishTypeId } from "utils/lightingUtils";
import { scrollIntoView } from "utils/scrollIntoView";

import NoFiltersMatchImage from "assets/illustrations/noMatchFiltersImage.svg";
import DishTypeItem from "components/dishTypeItem";
import { TutorialModalContent } from "components/drawerActionBar/components/TutorialModal/TutorialModalContent";
import { Header } from "components/global/components/Header";
import { BasicModal } from "components/global/modals/BasicModal";
import { useFilters } from "hooks/useFilters";
import { useFridgeLights } from "hooks/useFridgeLights";
import useMealListEvents from "hooks/useMealListEvents";
import { useAppDispatch, useAppSelector } from "hooks/utilsHooks/storeHooks";
import { LightingModeType, TransactionState } from "types";
import { AppState, DishItemDisplayType } from "types/mainTypes";

import { DownloadAppBanner } from "../howToBanner/DownloadAppBanner";

/**
 * Represents a component that displays a list of meal types.
 **/
export const MealList = () => {
	const openDetailDishTypeId = useAppSelector(selectOpenedDetailDishTypeId);
	const dispatch = useAppDispatch();
	const appState = useAppSelector(selectAppState);
	const isHowToBannerVisible = useAppSelector(selectHowToPanelVisibility);
	const order = useAppSelector(selectOrder);
	const availableDishTypeIds = useAppSelector(selectAvailableDishTypesIds);
	const fridgeDishes = useAppSelector(selectDishTypes);
	const availableDishTypes = useAppSelector(selectAvailableDishTypes);
	const unavailableDishTypes = useAppSelector(selectDishTypesNotIncludedInFilters);

	const { allergens, resetAllergens } = useFilters();
	const { toggleLight, dishTypeLights, toggleFridgeLightingMode, highlightSingleDishType } =
		useFridgeLights();
	const howToBannerRef = useRef<HTMLDivElement>(null);
	const {
		handleOpen,
		closeOpenedDishTypeDetail,
		handleLightsStateWhenFilterIsActive,
		openDishTypeDetail,
	} = useMealListEvents(availableDishTypeIds, fridgeDishes);

	const { t } = useTranslation();

	useEffect(function setInitialOpenedDishType() {
		if (dishTypeLights) {
			const initialHighlightedDishTypeId = getSpotlightDishTypeId(dishTypeLights);
			if (initialHighlightedDishTypeId !== null && allergens.length === 0) {
				openDishTypeDetail(initialHighlightedDishTypeId);
			}
		}
	}, []);

	useEffect(
		function handleFridgeLightAndDishDetailState() {
			// If user selects any filters, we highlight only filtered dish types
			if (allergens.length !== 0) {
				handleLightsStateWhenFilterIsActive();
				return;
			}
			if (appState !== AppState.IDLE) {
				if (openDetailDishTypeId) {
					handleOpen(openDetailDishTypeId);
				}
				if (order?.state !== TransactionState.Finished) {
					toggleFridgeLightingMode(LightingModeType.Active);
					return;
				}
			}
		},
		[allergens],
	);

	useEffect(
		function handleSingleDishTypeAction() {
			if (openDetailDishTypeId !== null && appState === AppState.ACTIVE) {
				highlightSingleDishType(toggleLight, availableDishTypeIds, openDetailDishTypeId);
				openDishTypeDetail(openDetailDishTypeId);
				return;
			}
			if (!openDetailDishTypeId && allergens.length > 0) {
				handleLightsStateWhenFilterIsActive();
				return;
			}
			if (!openDetailDishTypeId && appState === AppState.ACTIVE) {
				closeOpenedDishTypeDetail();
				toggleFridgeLightingMode(LightingModeType.Active);
			}
		},
		[openDetailDishTypeId],
	);

	useEffect(
		function resetViewWhenIdle() {
			if (appState === AppState.IDLE && !order) {
				scrollIntoView(howToBannerRef);
				dispatch(resetAppState());
				toggleFridgeLightingMode(LightingModeType.StandBy);
				return;
			}
			if (order && order.state === TransactionState.Finished) {
				scrollIntoView(howToBannerRef);
			}
		},
		[appState, order],
	);

	const isFridgeEmpty = useMemo(() => {
		return fridgeDishes && availableDishTypes.length === 0 && allergens.length > 0;
	}, [fridgeDishes, availableDishTypes, allergens]);

	const memoizedHandleOpen = useCallback(
		(dishTypeId: string, ref: RefObject<HTMLDivElement>) => {
			handleOpen(dishTypeId, ref);
		},
		[handleOpen],
	);

	return (
		<>
			<Header />
			{isFridgeEmpty && (
				<StyledEmptyFridgePageContent>
					<StyledEmptyFridgeHeadline variant={"promoHeader"}>
						{t("dishListPage.filteredEmptyState")}
					</StyledEmptyFridgeHeadline>
					<NoFiltersMatchImage />
				</StyledEmptyFridgePageContent>
			)}
			<StyledDishTypeItemsWrapper data-testid="meal-list-dishes">
				<BasicModal
					toggleButton={
						<DownloadAppBanner
							ref={howToBannerRef}
							isVisible={isHowToBannerVisible}
							toggleBanner={() => dispatch(setHowToBannerVisibility(false))}
						/>
					}
					closable={true}
					modalContent={<TutorialModalContent />}
					fullHeight={true}
				/>

				{availableDishTypes.map((dishType) => (
					<DishTypeItem
						dishType={dishType}
						type={DishItemDisplayType.LIST}
						isOpen={openDetailDishTypeId === dishType.id}
						onOpen={(ref: RefObject<HTMLDivElement>) =>
							memoizedHandleOpen(dishType.id, ref)
						}
						key={`mealListPage_item_${dishType.id}`}
					/>
				))}
				{allergens.length > 0 && (
					<>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<Typography color={"white"} variant={"mealSub"}>
								{t("filters.dishesNotMatchingFilters")}
							</Typography>
							<Button
								onClick={() => {
									resetAllergens();
								}}
								variant={"es-text"}
							>
								{t("filters.resetButton")}
							</Button>
						</Box>
						{unavailableDishTypes.map((dishType) => (
							<DishTypeItem
								dishType={dishType}
								type={DishItemDisplayType.LIST}
								isOpen={openDetailDishTypeId === dishType.id}
								onOpen={(ref: RefObject<HTMLDivElement>) =>
									memoizedHandleOpen(dishType.id, ref)
								}
								key={`mealListPage_item_${dishType.id}`}
								notFiltered
							/>
						))}
					</>
				)}
			</StyledDishTypeItemsWrapper>
		</>
	);
};

const StyledDishTypeItemsWrapper = styled(Box)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: theme.spacing(2),
	overflow: "auto",
	height: "calc(100vh - 350px)",
	paddingBottom: "200px",
}));

const StyledEmptyFridgePageContent = styled(Box)(({ theme }) => ({
	width: "100%",
	height: "100%",
	margin: "auto",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	paddingTop: theme.spacing(2.5),
}));

const StyledEmptyFridgeHeadline = styled(Typography)(({ theme }) => ({
	textAlign: "center",
	color: theme.palette.copper.main,
	marginBottom: theme.spacing(3),
}));

import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setQrCode } from "slices/accessSlice";

import OrderStep2Arrow from "assets/OrderStep2Arrow.svg";
import OpenFridgeImage from "assets/illustrations/openFridgePromptImage.svg";
import { BlurredBackground } from "components/primitives/BlurredBackground";
import { DrawerSubHeaderText } from "components/primitives/DrawerSubHeaderText";
import { DrawerToggleIndicator } from "components/primitives/DrawerToggleIndicator";
import { HeaderText } from "components/primitives/HeaderText";
import { SwipeableArea } from "components/primitives/SwipeableArea";
import { useAppDispatch, useAppSelector } from "hooks/utilsHooks/storeHooks";

import { useMixpanelTrackers } from "../../hooks/useMixpanelTrackers";
import { selectOrder } from "../../slices/transactionSlice";

export type BaseOrderStepProps = {
	open: boolean;
	toggleDrawer: (newState: boolean) => void;
};

export const OrderStep2 = ({ open, toggleDrawer }: BaseOrderStepProps) => {
	const dispatch = useAppDispatch();
	const order = useAppSelector(selectOrder);
	const { t } = useTranslation();
	const { trackOrderStart } = useMixpanelTrackers();

	useEffect(() => {
		trackOrderStart(order);
		dispatch(setQrCode(null));
	}, []);

	return (
		<>
			<SwipeableArea orderstep={2} open={open}>
				{!open && <BlurredBackground orderStep={2} open={open} />}
				<DrawerToggleIndicator open={open} toggleDrawer={toggleDrawer} />
				<Box
					sx={{ width: "100%", display: "flex", flexDirection: "column", paddingTop: 2 }}
				>
					<HeaderText variant={"h3"}>{t("order.step2.header")}</HeaderText>
					<OrderStep2Arrow />
					<DrawerSubHeaderText>{t("order.step2.subheader")}</DrawerSubHeaderText>
				</Box>
			</SwipeableArea>
			<StyledDrawerBody open={open}>
				<OpenFridgeImage />
			</StyledDrawerBody>
		</>
	);
};

const StyledDrawerBody = styled(Box, {
	shouldForwardProp: (prop) => prop !== "open",
})<BoxProps & { open: boolean }>(({ open }) => ({
	width: "100%",
	height: "760px",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "0 40px 120px 40px",
	justifyContent: "center",
	marginTop: open ? "200px" : "0px",
}));

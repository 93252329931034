import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { changeLanguage } from "i18next";
import { Config } from "services/configuration/eatSmartConfig";
import type { RootState } from "store";

import { AppLanguage, AppState, Filters } from "types/mainTypes";

import { selectOrder } from "./transactionSlice";

type AppStateSlice = {
	locale: string;
	selectedLanguage: AppLanguage;
	filters: Filters;
	appState: AppState;
	openedDetailDishTypeId: string | null;
	howToPanelVisible: boolean;
	drawerOpen: boolean;
	filterDebounceTimeoutId: number | null;
};

export const initialState: AppStateSlice = {
	locale: Config.common.defaultLocale,
	selectedLanguage:
		Config.common.defaultLocale === "cs-CZ" ? AppLanguage.PRIMARY : AppLanguage.SECONDARY,
	filters: {
		allergens: [],
	},
	appState: AppState.ACTIVE,
	openedDetailDishTypeId: null,
	howToPanelVisible: true,
	drawerOpen: true, // To be used for controlling the drawer state
	filterDebounceTimeoutId: null,
};

export const appStateSlice = createSlice({
	name: "appState",
	initialState,
	reducers: {
		setLocale: (state, { payload }: PayloadAction<string>) => {
			state.locale = payload;
		},
		setSelectedLanguage: (state, { payload }: PayloadAction<AppLanguage>) => {
			state.selectedLanguage = payload;
		},
		setFilters: (state, { payload }: PayloadAction<Filters>) => {
			state.filters = {
				...state.filters,
				...payload,
			};
		},
		setFilterDebounceTimeoutId: (state, { payload }: PayloadAction<number | null>) => {
			state.filterDebounceTimeoutId = payload;
		},
		setAppState: (state, { payload }: PayloadAction<AppState>) => {
			state.appState = payload;
		},
		setOpenedDetailDishTypeId: (state, { payload }: PayloadAction<string | null>) => {
			state.openedDetailDishTypeId = payload;
		},
		setHowToBannerVisibility: (state, { payload }: PayloadAction<boolean>) => {
			state.howToPanelVisible = payload;
		},
		resetAppState: (state) => {
			changeLanguage(Config.common.defaultLocale);
			state.selectedLanguage =
				Config.common.defaultLocale === "cs-CZ"
					? AppLanguage.PRIMARY
					: AppLanguage.SECONDARY;
			state.howToPanelVisible = true;
			state.openedDetailDishTypeId = null;
			state.filters = { allergens: [] };
			state.drawerOpen = true;
		},
	},
});

export const {
	setLocale,
	setOpenedDetailDishTypeId,
	setFilters,
	setAppState,
	setSelectedLanguage,
	setHowToBannerVisibility,
	resetAppState,
	setFilterDebounceTimeoutId,
} = appStateSlice.actions;
export const selectLocale = (state: RootState) => {
	return state.appState.locale;
};
export const selectFilterDebounceTimeoutId = (state: RootState) => {
	return state.appState.filterDebounceTimeoutId;
};
export const selectHowToPanelVisible = (state: RootState) => {
	return state.appState.howToPanelVisible;
};
export const selectOpenedDetailDishTypeId = (state: RootState) => {
	return state.appState.openedDetailDishTypeId;
};
export const selectSelectedLanguage = (state: RootState) => state.appState.selectedLanguage;
export const selectHowToPanelVisibility = createSelector(
	selectOrder,
	selectHowToPanelVisible,
	(order, visibility) => {
		if (order) {
			return false;
		}
		return visibility;
	},
);
export const selectAppState = (state: RootState) => state.appState.appState;
export const selectFilters = (state: RootState) => state.appState.filters;
export const selectIsDrawerOpen = (state: RootState) => state.appState.drawerOpen;

export default appStateSlice.reducer;

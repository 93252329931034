import { selectAvailableDishTypesIds, selectIsFridgeEmpty } from "slices/dishesSlice";
import { selectOrder, selectOrderDishes } from "slices/transactionSlice";

import { OrderProcessDrawer } from "components/appDrawer/OrderProcessDrawer";
import { EmptyFridgeModal } from "components/global/modals/EmptyFridgeModal";
import { MealList } from "components/mealList/MealList";
import { useAppSelector } from "hooks/utilsHooks/storeHooks";
import { DefaultLayout } from "layouts/DefaultLayout";

export const MealsListPage = () => {
	const availableDishTypesIds = useAppSelector(selectAvailableDishTypesIds);
	const orderDishes = useAppSelector(selectOrderDishes);
	const order = useAppSelector(selectOrder);
	const isFridgeEmpty = useAppSelector(selectIsFridgeEmpty);

	const hasAvailableDishes = availableDishTypesIds.size > 0;
	const hasOrderDishes = orderDishes.length > 0;

	return (
		<DefaultLayout>
			{(hasAvailableDishes || hasOrderDishes) && (
				<>
					{hasAvailableDishes && <MealList />}
					<OrderProcessDrawer />
				</>
			)}
			{isFridgeEmpty && !order && <EmptyFridgeModal />}
		</DefaultLayout>
	);
};

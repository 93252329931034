import { useMutation } from "@apollo/client";
import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { SEND_FEEDBACK_MUTATION } from "utils/gqlOperations";

import SomethingWentWrongIcon from "assets/icons/errorIcon.svg";
import JustTryingOutIcon from "assets/icons/justLookingIcon.svg";
import OtherIssueIcon from "assets/icons/otherIcon.svg";
import NoInterestingFoodIcon from "assets/icons/sadFaceSquared.svg";
import MealNotChargedIcon from "assets/icons/unchargedMealIcon.svg";
import { SnackbarContent } from "components/global/snackbar/SnackbarContent";
import { useSnackbarContext } from "contexts/SnackbarContext";
import { useFridgeInformation } from "hooks/useFridgeInformation";
import { useMixpanelTrackers } from "hooks/useMixpanelTrackers";
import { useOrder } from "hooks/useOrder";
import { OrderFeedbackRatingType, OrderFeedbackReasonType } from "types";

const EMPTY_ORDER_FEEDBACK = [
	{
		value: OrderFeedbackReasonType.NOTHING_INTERESTING,
		label: "nothingInteresting" as const,
		icon: <NoInterestingFoodIcon />,
	},
	{
		value: OrderFeedbackReasonType.SOMETHING_WENT_WRONG,
		label: "error" as const,
		icon: <SomethingWentWrongIcon />,
	},
	{
		value: OrderFeedbackReasonType.NOT_CHARGED,
		label: "mealNotCharged" as const,
		icon: <MealNotChargedIcon />,
	},
	{
		value: OrderFeedbackReasonType.JUST_LOOKING,
		label: "justTesting" as const,
		icon: <JustTryingOutIcon />,
	},
	{ value: OrderFeedbackReasonType.OTHER, label: "other" as const, icon: <OtherIssueIcon /> },
];
export const EmptyOrderState = ({ transactionId }: { transactionId: string | null }) => {
	const { t } = useTranslation();
	const { id } = useFridgeInformation();
	const { finishOrder } = useOrder();
	const { openSnackbarWithContent } = useSnackbarContext();
	const { trackFinishedEmptyOrderFeedback } = useMixpanelTrackers();

	const [sendEmptyOrderFeedbackMutation, { loading }] = useMutation(SEND_FEEDBACK_MUTATION);
	// We do not really care when the feedback is sent or finished sending,
	// we just go on so next customer can buy food
	// eslint-disable-next-line @typescript-eslint/require-await
	const handleChange = async (reason: OrderFeedbackReasonType) => {
		const isFeedbackPositive =
			reason === OrderFeedbackReasonType.JUST_LOOKING ||
			reason === OrderFeedbackReasonType.NOTHING_INTERESTING;
		if (id) {
			openSnackbarWithContent(
				<SnackbarContent
					variant={isFeedbackPositive ? "feedbackPositive" : "feedbackNegative"}
				/>,
			);
			if (transactionId) {
				sendEmptyOrderFeedbackMutation({
					variables: {
						input: {
							orderId: transactionId,
							type: isFeedbackPositive
								? OrderFeedbackRatingType.POSITIVE
								: OrderFeedbackRatingType.NEGATIVE,
							reason: reason,
						},
					},
				});
			}
		}
		trackFinishedEmptyOrderFeedback(reason);
		finishOrder();
	};

	return (
		<Box display={"flex"} flexDirection={"column"} gap={2} width={"100%"}>
			{EMPTY_ORDER_FEEDBACK.map((feedbackItem) => (
				<StyledFeedbackButton
					key={`feedback_${feedbackItem.value}`}
					onClick={() => handleChange(feedbackItem.value)}
					disabled={loading}
				>
					{feedbackItem.icon}
					{t(`order.step5.emptyOrder.feedback.${feedbackItem.label}`)}
				</StyledFeedbackButton>
			))}
		</Box>
	);
};

const StyledFeedbackButton = styled(IconButton)(({ theme }) => ({
	display: "flex",
	justifyContent: "flex-start",
	width: "100%",
	padding: "20px 24px",
	borderRadius: theme.shape.borderRadius,
	gap: theme.spacing(2),
	backgroundColor: theme.palette.common.grey[9],
	color: theme.palette.common.white,

	"&:hover": {
		backgroundColor: theme.palette.common.grey[9],
	},
}));

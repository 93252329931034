import { useCallback } from "react";
import { Config } from "services/configuration/eatSmartConfig";
import {
	selectFilterDebounceTimeoutId,
	selectFilters,
	setFilterDebounceTimeoutId,
	setFilters,
} from "slices/appStateSlice";

import { Filters } from "types/mainTypes";

import { useMixpanelTrackers } from "./useMixpanelTrackers";
import { useAppDispatch, useAppSelector } from "./utilsHooks/storeHooks";

export const useFilters = () => {
	const filters = useAppSelector(selectFilters);
	const filterDebounceTimeoutId = useAppSelector(selectFilterDebounceTimeoutId);
	const dispatch = useAppDispatch();
	const { trackManualFilterApplied } = useMixpanelTrackers();

	const clearTrackingTimeout = () => {
		if (filterDebounceTimeoutId !== null) {
			window.clearTimeout(filterDebounceTimeoutId);
			dispatch(setFilterDebounceTimeoutId(null));
		}
	};

	const handleSetFilters = useCallback(
		(payload: Filters, immediate = false) => {
			if (!payload.allergens) return;

			// Clear any pending timeout
			clearTrackingTimeout();

			dispatch(setFilters(payload));

			if (immediate) {
				trackManualFilterApplied(payload.allergens);
			} else {
				const allergensSnapshot = payload.allergens;
				const timeoutId = window.setTimeout(() => {
					trackManualFilterApplied(allergensSnapshot);
				}, Config.filterMixpanelTrackingDebounce);
				dispatch(setFilterDebounceTimeoutId(timeoutId));
			}
		},
		[trackManualFilterApplied, clearTrackingTimeout],
	);

	const resetAllergens = useCallback(() => {
		if (filters.allergens.length > 0) {
			handleSetFilters({ allergens: [] }, true);
		}
	}, [filters.allergens.length, handleSetFilters, clearTrackingTimeout]);

	return {
		allergens: filters.allergens,
		setFilters: handleSetFilters,
		resetAllergens,
	};
};

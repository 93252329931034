import { isObject, keyBy, omit, transform } from "lodash";

import {
	AllergenType,
	Dish,
	DishSubscription,
	DishType,
	DishTypeSubscription,
	Maybe,
	Transaction,
	TransactionState,
} from "types";
import { LocalDishType } from "types/mainTypes";

import EAT_PERFECT_THEME from "./theme";

/**
 * Returns Set of dish type ids that are available in the fridge or in the order
 * @param fridgeDishTypes
 * @param orderDishes
 * @param orderState
 */
export const getAvailableDishTypeIds = (
	fridgeDishTypes: LocalDishType[] | null,
	orderDishes: Maybe<Dish[]> | undefined,
	orderState?: Maybe<TransactionState>,
) => {
	// Safe defaults
	const fridgeDishTypesSafe = fridgeDishTypes || [];
	const orderDishesSafe = orderDishes || [];

	// In case of active order, we want to keep these dishtypes displayed on the screen
	if (orderState && orderState !== TransactionState.Finished && orderDishesSafe.length) {
		// Get dish type IDs from the active order
		const orderDishTypeIds = new Set(
			orderDishesSafe
				.filter((orderDish) => !!orderDish?.type?.id)
				.map((orderDish) => orderDish.type!.id), // Non-null assertion since filtered above
		);

		// Get dish type IDs from fridge
		return new Set(
			fridgeDishTypesSafe
				.filter((dishType) => dishType.dishes?.length || orderDishTypeIds.has(dishType.id))
				.map((dishType) => dishType.id),
		);
	}

	// Once, the order is finished, we will display only dishtypes that are available in fridge
	return new Set(
		fridgeDishTypesSafe
			.filter((dishType) => dishType?.dishes?.length)
			.map((dishType) => dishType.id),
	);
};

export const mergeDishTypeDishes = (
	originalDishes: Maybe<Dish[]> | undefined | null,
	updatedDishes: Maybe<DishSubscription[]> | undefined | null,
): Dish[] | null => {
	if (updatedDishes && updatedDishes.length === 0) {
		return [];
	}
	if (!updatedDishes && originalDishes) {
		return originalDishes;
	}

	if (!originalDishes && !updatedDishes) {
		return null;
	}

	if (!originalDishes && updatedDishes) {
		return updatedDishes as Dish[];
	}

	const result = updatedDishes?.map((updatedDish) => {
		const originalDish = originalDishes?.find(
			(originalDish) => originalDish.id === updatedDish.id,
		);
		if (originalDish !== undefined && updatedDish) {
			return mergeValues(originalDish as Dish, updatedDish) as Dish;
		}
		return updatedDish as Dish;
	});

	if (result) {
		return result;
	}

	return null;
};

/**
 * Merges original dish type with updated dish type
 * @param originalDishType
 * @param updatedDishType
 */
export const mergeDishType = (
	originalDishType: Partial<DishType>,
	updatedDishType: Partial<DishTypeSubscription>,
) => {
	let result = {};
	let dishes: Dish[] | null = null;
	let allergens: AllergenType[] | null = null;

	// When there is no original dish type, we want to return updated dish type
	if (!originalDishType && updatedDishType) {
		return updatedDishType as LocalDishType;
	}

	// When there is no updated dish type, we want to return original dish type
	if (!updatedDishType && !originalDishType) {
		return null;
	}

	// If updated dish type updates some values in original one,we merge it
	for (const key in updatedDishType) {
		const originalValue = originalDishType[key as keyof DishType];
		const updatedValue = updatedDishType[key as keyof DishType];

		if (
			key === "dishes" &&
			Array.isArray(originalDishType[key]) &&
			!updatedValue &&
			originalValue
		) {
			dishes = originalValue as Dish[];
		}

		if (key === "dishes" && Array.isArray(updatedDishType[key])) {
			const mergedDishes = mergeDishTypeDishes(
				originalDishType.dishes,
				updatedDishType.dishes,
			);
			if (!mergedDishes) {
				return null;
			}
			dishes = mergedDishes;
		} else if (key === "allergens") {
			if (updatedValue) {
				allergens = updatedValue as AllergenType[];
			} else {
				allergens = originalValue as AllergenType[];
			}
		} else if (originalValue && typeof updatedValue === "object") {
			if (updatedValue !== null) {
				result = {
					...result,
					[key]: mergeDishType(
						originalValue as Partial<DishType>,
						updatedValue as Partial<DishTypeSubscription>,
					),
				};
			} else {
				result = {
					...result,
					[key]: originalValue,
				};
			}
		} else if (
			(updatedValue === null && originalValue !== null) ||
			(updatedValue === undefined && originalValue !== null)
		) {
			result = {
				...result,
				[key]: originalValue,
			};
		} else {
			result = {
				...result,
				[key]: updatedValue,
			};
		}
	}
	return {
		...originalDishType,
		...updatedDishType,
		...omit(result, "dishes", "allergens"),
		...(dishes && { dishes }),
		...(allergens && { allergens }),
	};
};

/**
 * Merges original dish types with updated dish types
 * @param originalDishTypes
 * @param updatedDishTypes
 */
export const mergeDishTypes = (
	originalDishTypes: LocalDishType[],
	updatedDishTypes: (LocalDishType | undefined)[],
): LocalDishType[] => {
	const updatedDihTypesSafe = updatedDishTypes.filter((dishType) => dishType !== undefined);

	if ((!updatedDihTypesSafe || updatedDihTypesSafe.length === 0) && originalDishTypes) {
		return originalDishTypes;
	}
	if ((!originalDishTypes || originalDishTypes.length === 0) && updatedDihTypesSafe) {
		return updatedDihTypesSafe;
	}

	if (!updatedDihTypesSafe && !originalDishTypes) {
		return [];
	}

	const result = originalDishTypes.map((originalDishType) => {
		const dishTypeToUpdate = updatedDihTypesSafe?.find(
			(updatedDishType) => originalDishType.id === updatedDishType.id,
		);
		if (dishTypeToUpdate !== undefined) {
			return mergeDishType(originalDishType, dishTypeToUpdate);
		}
		return originalDishType;
	});

	return result.filter(
		(dishType) => dishType !== null && dishType !== undefined,
	) as LocalDishType[];
};

/**
 * Merges two objects recursively based on our custom logic
 * @param original
 * @param update
 */
export const mergeValues = <T>(original: T, update: T): T => {
	let merged: T;
	// If both values are null, return null
	if (!original && !update) {
		return original;
	}

	if (original !== null && update === null) {
		return original;
	}

	if (typeof original === "object" && !Array.isArray(original)) {
		merged = { ...(original as object) } as T;
	} else if (typeof original === "object" && Array.isArray(original)) {
		merged = [...original] as T;
	} else {
		merged = original;
	}
	if (!original && update) {
		return update;
	}

	if (typeof update === "object") {
		for (const key in update) {
			/* If we receive new order, we want to replace the old one (eg. orderSummary modal
			is displayed with current order and we receive new order from subscription
			 */
			if (key === "order") {
				const originalOrder = original[key as keyof T] as Transaction;
				const updatedOrder = update[key as keyof T] as Transaction;
				if (originalOrder?.id !== updatedOrder?.id) {
					merged[key] = update[key];
					break;
				}
			}

			// For removed dishes in order, we want to always use the updated value
			if (key === "removed") {
				merged[key] = update[key];
				break;
			}

			// If we update object, we have to merge it recursively
			if (typeof update[key] === "object" && !Array.isArray(update[key])) {
				merged[key] = mergeValues(original[key], update[key]);
			} else if (Array.isArray(update[key])) {
				merged[key] = mergeValues(original[key], update[key]);
			} else if (
				(update[key] === null || update[key] === undefined) &&
				original[key] !== null
			) {
				break;
			} else {
				merged[key] = update[key];
			}
		}
	}
	return merged;
};

export const deepOmit = <T, K>(obj: T, keysToOmit: string[]): K => {
	const keysToOmitIndex = keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]);

	// create an index object of the keys that should be omitted

	function omitFromObject(obj: T): K {
		// the inner function which will be called recursively

		return transform(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			obj,
			function (result: object, value: object | string | number, key: string) {
				// transform to a new object
				if (key in keysToOmitIndex) {
					// if the key is in the index skip it
					return;
				}

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				result[key] = isObject(value) ? omitFromObject(value) : value;
				// if the key is an object run it through the inner function - omitFromObject
			},
		);
	}

	return omitFromObject(obj); // return the inner function result
};
export const getBackgroundColor = (orderStep: number, theme: typeof EAT_PERFECT_THEME): string => {
	switch (orderStep) {
		case 1: {
			return theme.palette.copper.main;
		}
		case 2: {
			return theme.palette.gold.main;
		}
		case 3: {
			return theme.palette.green.main;
		}
		case 4: {
			return theme.palette.green.main;
		}
		case 5: {
			return theme.palette.silver.main;
		}
		default: {
			return theme.palette.copper.main;
		}
	}
};

const DISH_TYPE_HEADER_MAX_LENGTH = 28;

/**
 * Splits dish name - this is utility function, that should improve readability of dish names,
 * it should keep the header in proper length and move the rest to subheader.
 * @param dishTypeName
 */
export const createSplitDishName = (dishTypeName: Maybe<string> | undefined) => {
	let header = "";
	let subHeader = "";
	let isFullRow = false;

	if (!dishTypeName) {
		return null;
	}

	const cleanedDishTypeName = dishTypeName.replace("X - ", "");

	if (cleanedDishTypeName.includes("|||")) {
		return {
			header: cleanedDishTypeName.split("|||")[0],
			subHeader: cleanedDishTypeName.split("|||")[1],
		};
	}

	const words = cleanedDishTypeName.split(" ");
	words.forEach((word) => {
		if (header.length < DISH_TYPE_HEADER_MAX_LENGTH && !isFullRow) {
			const updatedHeader = header + word + " ";
			if (updatedHeader.length <= DISH_TYPE_HEADER_MAX_LENGTH && subHeader.length === 0) {
				if (word === "s" || word === "z") {
					subHeader += word + " ";
				} else {
					header = updatedHeader;
				}
			} else {
				isFullRow = true;
				subHeader += word + " ";
			}
		} else {
			subHeader += word + " ";
		}
	});

	return {
		header,
		subHeader,
	};
};

/**
 * Temporary function to get dish type text value correctly formatted to title
 * and subtitle, this should be handled in administration and DB
 * @param dishType
 * @param language
 * @param value
 */
export const getDishTypeTextValue = (
	dishType: LocalDishType,
	language: "primary" | "secondary",
	value: "description" | "composition",
) => {
	if (dishType.description![language]![value] !== null) {
		return dishType.description![language]![value] as string;
	}
	return "No value";
};
export const parseInstructions = (dishTypeDescription: string | null) => {
	if (!dishTypeDescription) {
		return ["", ""];
	}
	if (dishTypeDescription.includes("|||")) {
		return dishTypeDescription.split("|||");
	} else if (dishTypeDescription.includes("Použití")) {
		return dishTypeDescription.split("Použití");
	}
	return dishTypeDescription;
};

export function getShortenedFridgeId(fridgeId?: string | null) {
	if (!fridgeId) {
		return "";
	}
	return fridgeId.substring(fridgeId.length - 5, fridgeId.length);
}

import { Menu } from "@mui/material";
import { styled } from "@mui/material/styles";
import { changeLanguage } from "i18next";
import React, { useState } from "react";
import { selectSelectedLanguage, setSelectedLanguage } from "slices/appStateSlice";

import CzechIcon from "assets/flags/czech.svg";
import EnglishIcon from "assets/flags/english.svg";
import { ActionButton } from "components/primitives/ActionBarButton";
import { useAppDispatch, useAppSelector } from "hooks/utilsHooks/storeHooks";
import { AppLanguage } from "types/mainTypes";

import { useMixpanelTrackers } from "../../hooks/useMixpanelTrackers";
import { LanguagePickerOption } from "./LanguagePickerOption";

const LanguagesConfig = [
	{
		name: "Čeština",
		icon: CzechIcon,
		type: AppLanguage.PRIMARY,
	},
	{
		name: "English",
		icon: EnglishIcon,
		type: AppLanguage.SECONDARY,
	},
] as const;

export const LanguagePicker = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const dispatch = useAppDispatch();
	const selectedLanguage = useAppSelector(selectSelectedLanguage);
	const { trackTabletLanguageChange } = useMixpanelTrackers();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const selectLanguage = async (lang: AppLanguage) => {
		if (lang !== selectedLanguage) {
			const newLanguage = lang === AppLanguage.PRIMARY ? "cs" : "en";
			// i18n is changed based on this value in AppContext
			trackTabletLanguageChange(newLanguage, "manual");
			await changeLanguage(newLanguage);
			dispatch(setSelectedLanguage(lang));
		}
		handleClose();
	};

	return (
		<>
			<ActionButton onClick={handleClick}>
				{selectedLanguage === AppLanguage.PRIMARY ? <CzechIcon /> : <EnglishIcon />}
			</ActionButton>
			<StyledMenu
				id="language-picker"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "language-picker",
				}}
				hideBackdrop={false}
			>
				{LanguagesConfig.map((lang) => (
					<LanguagePickerOption
						key={lang.name}
						onClick={() => selectLanguage(lang.type)}
						languageName={lang.name}
						languageIcon={lang.icon}
					/>
				))}
			</StyledMenu>
		</>
	);
};

const StyledMenu = styled(Menu)(() => ({
	padding: "10px",
	zIndex: 10000,
	width: "100%",
	"& .MuiBackdrop-root": {
		backgroundColor: "#00000075",
	},
	"& .MuiPaper-root": {
		width: "fit-content",
	},
}));

import * as Sentry from "@sentry/react";
import { useIdleTimerContext } from "react-idle-timer";
import { batch } from "react-redux";
import { resetAppState } from "slices/appStateSlice";
import { finishOrderState } from "slices/transactionSlice";

import { useAnalyticsContext } from "contexts/AnalyticsContext";
import { useAppDispatch } from "hooks/utilsHooks/storeHooks";

type AWSOrderUpdate = {
	finishOrder: () => void;
};

export const useOrder = (): AWSOrderUpdate => {
	const dispatch = useAppDispatch();
	const idleTimer = useIdleTimerContext();
	const analyticsContext = useAnalyticsContext();

	const finishOrder = () => {
		try {
			idleTimer.reset();
			analyticsContext.reset();
			batch(() => {
				dispatch(finishOrderState());
				dispatch(resetAppState());
			});
			return true;
		} catch (error) {
			Sentry.captureException(new Error("Error while finishing order: " + error));
			return false;
		}
	};

	return {
		finishOrder,
	};
};

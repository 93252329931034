import { FormControlLabel, FormControlLabelProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledFormControlLabel = styled(FormControlLabel, {
	shouldForwardProp: (prop) => prop !== "isFiltered",
})<FormControlLabelProps & { isFiltered: boolean }>(({ theme, isFiltered }) => ({
	backgroundColor: isFiltered ? theme.palette.common.white : "#FFFFFF25",
	padding: "8px 8px 8px 16px",
	marginLeft: 0,
	marginRight: "8px",
	"& .MuiCheckbox-root": {
		display: "none",
	},
	color: theme.palette.common.white,

	display: "flex",
	justifyContent: "flex-start",
	alignItems: "center",
	borderRadius: "80px",

	"span > .MuiBox-root": {
		fontWeight: 900,
	},
	'&[data-state="checked"]': {
		color: "$white",
	},
}));

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
	DRAWER_CLOSED_HEIGHT,
	DRAWER_SWIPEABLE_AREA_HEIGHT,
	DRAWER_TOTAL_HEIGHT,
	ORDER_STEP_1_SIZE_LARGE,
} from "utils/designTokens";

const getSwipeableAreaTopPosition = (orderStep: number, open: boolean | undefined) => {
	if (orderStep === 1 && open) {
		return 0;
	} else if (orderStep === 1 || !open) {
		return -DRAWER_CLOSED_HEIGHT;
	}
	return 0;
};

const getSwipeableAreaHeight = (orderStep: number, open: boolean | undefined) => {
	if (orderStep === 1 && open) {
		return ORDER_STEP_1_SIZE_LARGE;
	} else if (orderStep === 1 || !open) {
		return DRAWER_TOTAL_HEIGHT;
	}
	return DRAWER_SWIPEABLE_AREA_HEIGHT;
};

export const SwipeableArea = styled(Box)<BoxProps & { orderstep: number; open?: boolean }>(
	({ theme, ...props }) => ({
		position: "absolute",
		top: getSwipeableAreaTopPosition(props.orderstep, props.open),
		height: getSwipeableAreaHeight(props.orderstep, props.open),
		borderTopLeftRadius: theme.shape.borderRadius,
		borderTopRightRadius: theme.shape.borderRadius,
		visibility: "visible",
		right: 0,
		left: 0,
		padding: "40px",
		backgroundColor: props.orderstep === 1 ? `${theme.palette.copper.main}` : "transparent",
	}),
);
export const Puller = styled(Box)(({ theme }) => ({
	width: 64,
	height: 4,
	backgroundColor: theme.palette.common.white,
	borderRadius: 3,
	position: "absolute",
	top: 16,
	left: "50%",
	transform: "translateX(-50%)",
}));
export const CloseButton = styled(KeyboardDoubleArrowDownIcon)(({ theme }) => ({
	height: 40,
	borderRadius: 3,
	position: "absolute",
	top: 5,
	left: "50%",
	transform: "translateX(-50%)",
	fill: theme.palette.common.white,
}));

import { useLazyQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { Config } from "services/configuration/eatSmartConfig";
import { setInitialDishTypes } from "slices/dishesSlice";
import { AppDispatch } from "store";
import { FETCH_DISH_TYPES } from "utils/gqlOperations";
import { log } from "utils/logger";
import { getRandomNumberInRange } from "utils/math";
import { getMilliSecondsToNextHour } from "utils/time";
import { deepOmit } from "utils/utils";

import { Fridge, TransactionState } from "types";
import { LocalFridgeState } from "types/mainTypes";

import { selectOrder } from "../slices/transactionSlice";
import { useInterval, useTimeout } from "./timers";
import { useAppDispatch, useAppSelector } from "./utilsHooks/storeHooks";

interface Props {
	fridgeId: string;
}

export const handleSetDishTypes = (data: Fridge | LocalFridgeState, appDispatch: AppDispatch) => {
	const cleanedData = deepOmit(data, ["__typename"]) as LocalFridgeState;
	appDispatch(setInitialDishTypes(cleanedData.dishes?.types));
};

const getInitialRefetchInterval = () => {
	const isProduction = import.meta.env.MODE === "production";
	const overrideVariable = import.meta.env.VITE_REFETCH_NEXT_HOUR_OVERRIDE;

	if (isProduction) {
		return getMilliSecondsToNextHour();
	}
	if (overrideVariable === undefined) {
		return getMilliSecondsToNextHour();
	}
	return Number.parseInt(import.meta.env.VITE_REFETCH_NEXT_HOUR_OVERRIDE);
};

export const useRefetchDishTypesQuery = ({ fridgeId }: Props) => {
	const appDispatch = useAppDispatch();
	const order = useAppSelector(selectOrder);
	const [isFirstRefetch, setIsFirstRefetch] = useState(true);
	const [refetchInterval, setRefetchInterval] = useState<number | null>(
		getInitialRefetchInterval(),
	);
	const [refetchDebounceTimeout, setRefetchDebounceTimeout] = useState<number | null>(null);
	const [isDeferredRefetchPending, setIsDeferredRefetchPending] = useState(false);
	const { initialStateQueryRefetchInterval, initialStateQueryRefetchDebounce } =
		Config.connectionSettings;

	const [getDishTypes, { loading }] = useLazyQuery(FETCH_DISH_TYPES, {
		variables: { id: fridgeId },
		fetchPolicy: "network-only",
		refetchWritePolicy: "overwrite",
		onCompleted: ({ fridge }) => {
			handleSetDishTypes(fridge, appDispatch);
		},
	});

	const handleRefetchDishTypes = async () => {
		setIsDeferredRefetchPending(false);
		log(`Refetching prices for fridge: ${fridgeId}`, "info");

		const response = await getDishTypes();
		log(`Response from price refetch for fridge: ${fridgeId}`, "warn");

		if (response && response.data) {
			handleSetDishTypes(response.data.fridge, appDispatch);
		} else {
			Sentry.captureException(new Error("Cannot refetch data"));
		}
	};

	// setup periodic refetch interval
	useInterval(() => {
		// after first refetch (that is triggered next hour) set the interval
		if (isFirstRefetch) {
			setRefetchInterval(initialStateQueryRefetchInterval);
			setIsFirstRefetch(false);
		}
		// add debounce to the request not to overload the server
		const debounce = getRandomNumberInRange(0, initialStateQueryRefetchDebounce);
		setRefetchDebounceTimeout(debounce);
	}, refetchInterval);

	// setup timeout for debounced refetch
	useTimeout(() => {
		if (!order || order.state === TransactionState.Finished) {
			handleRefetchDishTypes();
		} else {
			// defer the refetch after the order is finished (handled by DeviceContext)
			setIsDeferredRefetchPending(true);
		}
	}, refetchDebounceTimeout);

	return {
		loading,
		handleRefetchDishTypes,
		isDeferredRefetchPending,
	};
};

import { Box, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { AllergensMapping } from "utils/AllergensMapping";

import { AllergenType } from "types";

import { FilterChipLabel } from "./FilterChipLabel";
import { StyledFormControlLabel } from "./StyledFilterControlLabel";

export const SelectedFilters = ({
	allergens,
	handleFilterChange,
}: {
	allergens: string[];
	handleFilterChange: (checkState: string | boolean, filterValue: AllergenType) => void;
}) => {
	const { t } = useTranslation();
	return (
		<>
			{allergens.length > 0 && (
				<StyledSelectedFilterWrapper>
					{AllergensMapping.filter((allergen) => allergens.includes(allergen.name)).map(
						(allergen) => (
							<StyledFormControlLabel
								key={`quickFilter_${allergen.name}`}
								control={<Checkbox />}
								checked={true}
								onChange={(e, checked) =>
									handleFilterChange(checked, allergen.name)
								}
								isFiltered={true}
								label={
									<FilterChipLabel
										label={t(`allergens-free.${allergen.name}`)}
										isFiltered={true}
										icon={allergen.icon}
									/>
								}
							/>
						),
					)}
				</StyledSelectedFilterWrapper>
			)}
		</>
	);
};

const StyledSelectedFilterWrapper = styled(Box, {
	name: "StyledSelectedFilterWrapper",
})(({ theme }) => ({
	display: "flex",
	flexWrap: "wrap",
	width: "100%",
	color: theme.palette.common.white,
	gap: theme.spacing(1),
	alignItems: "center",
}));

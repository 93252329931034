import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * Timer that displays information about when the fridge will lock itself
 * @param lockTimeout
 * @constructor
 */
export const AutolockTimer = ({ lockTimeout }: { lockTimeout: number }) => {
	const [countdown, setCountdown] = useState(lockTimeout);
	const { t } = useTranslation();

	useEffect(() => {
		const timer = setInterval(() => {
			if (countdown > 0) {
				setCountdown((prevState) => prevState - 1);
			}
		}, 1000);
		return () => clearTimeout(timer);
	}, []);

	if (countdown > 0) {
		return (
			<Typography variant={"h5"} color={"white"} fontWeight={500}>
				{t("order.step2.autolock", { countdown })}
			</Typography>
		);
	}
	return null;
};

import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Config } from "services/configuration/eatSmartConfig";

export const DishTypeImage = styled(Box, {
	shouldForwardProp: (propName: PropertyKey) =>
		propName !== "imageUrl" && propName !== "notFiltered",
	name: "DishTypeImage",
})<BoxProps & { imageUrl?: string; notFiltered?: boolean }>(({ theme, imageUrl, notFiltered }) => ({
	backgroundImage: `url(${imageUrl ?? Config.common.dishTypePlaceholderImage})`,
	minHeight: "148px",
	minWidth: "114px",
	backgroundSize: "199px",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "-86px -20px",
	position: "absolute",
	left: "0px",
	top: 0,
	borderTopLeftRadius: theme.shape.borderRadius,
	borderBottomLeftRadius: theme.shape.borderRadius,
	transition: "all 200ms linear",
	...(notFiltered && {
		opacity: 0.32,
	}),

	"&.expanded": {
		minHeight: "240px",
		minWidth: "177px",
		backgroundSize: "314px",
		backgroundPosition: "-143px -30px",
		transform:
			"scale(2) translateX(-10px) translateY(-10px) minHeight minWidth backgroundSize backgroundPosition",
		borderBottomLeftRadius: 0,
	},

	"&.order": {
		backgroundPosition: "top -20px left -55px",
		backgroundSize: "120px",
		height: "84px",
		width: "84px",
		minWidth: "84px",
		minHeight: "84px",
		top: 0,
		left: 0,
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
	},
}));

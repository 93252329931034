import { Box } from "@mui/material";
import { ForwardedRef, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SnackbarHeader } from "components/primitives/snackbar/SnackbarHeader";
import { SnackbarSubHeader } from "components/primitives/snackbar/SnackbarSubtitle";
import { Maybe, User } from "types";

import { SnackbarType, getSnackbarData } from "./snackbarTypes";

type Props = {
	user?: Maybe<User>;
	variant: SnackbarType;
	subheader?: string;
};

const SnackbarContentComp = (
	{ user, variant, subheader: subheaderOverride }: Props,
	ref: ForwardedRef<unknown>,
) => {
	const { t } = useTranslation();

	const {
		header,
		subheader: subheaderDefault,
		image,
	} = useMemo(() => getSnackbarData(t, variant, user?.name ?? ""), [t, variant, user]);

	const subheader = subheaderOverride ? subheaderOverride : subheaderDefault;

	return (
		<Box display={"flex"} justifyContent={"space-between"} ref={ref} sx={{ width: "100%" }}>
			<Box
				display={"flex"}
				flexDirection={"column"}
				alignItems={"flex-start"}
				justifyContent={"center"}
			>
				<SnackbarHeader>{header}</SnackbarHeader>
				{subheader && <SnackbarSubHeader>{subheader}</SnackbarSubHeader>}
			</Box>
			<img src={image.src} alt={image.alt} width={132} height={132} />
		</Box>
	);
};

export const SnackbarContent = forwardRef(SnackbarContentComp);

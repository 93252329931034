import { Box, Button, IconButtonProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";

import FilterIcon from "assets/icons/filterIcon.svg";
import { useMixpanelTrackers } from "hooks/useMixpanelTrackers";

export const FilterButton = ({
	allergens,
	onClick,
}: {
	allergens: string[];
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
	const { t } = useTranslation();
	const { trackFilterWindowOpen } = useMixpanelTrackers();

	const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		trackFilterWindowOpen();
		onClick && onClick(e);
	};

	return (
		<StyledFilterButton activeFilters={allergens.length > 0} onClick={(e) => handleOnClick(e)}>
			<Typography variant={"filter"}>{t("filters.title")}</Typography>
			{allergens.length === 0 && (
				<FilterIcon color={"white"} width={"28px"} height={"28px"} />
			)}
			{allergens.length > 0 && (
				<Box
					sx={({ palette, spacing }) => ({
						backgroundColor: palette.copper.main,
						borderRadius: "100%",
						width: spacing(3.5),
						height: spacing(3.5),
						padding: spacing(0.5),
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					})}
				>
					<Typography
						variant={"filter"}
						sx={({ palette }) => ({
							color: palette.common.black,
						})}
						fontSize={"1.25rem"}
						lineHeight={"1.25rem"}
					>
						{allergens.length}
					</Typography>
				</Box>
			)}
		</StyledFilterButton>
	);
};

const StyledFilterButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== "activeFilters",
})<IconButtonProps & { activeFilters?: boolean }>(({ theme, activeFilters }) => {
	return {
		marginRight: theme.spacing(2),
		backgroundColor: activeFilters ? theme.palette.common.white : "#FFFFFF25",
		"&:hover": {
			backgroundColor: theme.palette.common.white,
		},
		"&:hover .MuiTypography-filter": {
			color: theme.palette.common.black,
		},
		"&:hover svg > path": {
			fill: theme.palette.common.black,
			stroke: theme.palette.common.black,
		},
		"& .MuiTypography-filter": {
			color: activeFilters ? theme.palette.common.black : theme.palette.common.white,
		},
		"& svg > path": {
			fill: activeFilters ? theme.palette.common.black : theme.palette.common.white,
			stroke: activeFilters ? theme.palette.common.black : theme.palette.common.white,
		},
		"& .MuiCheckbox-root": {
			display: "none",
		},
		padding: "8px 12px 8px 20px",
		minHeight: "48px",
		display: "flex",
		justifyContent: "flex-start",
		gap: theme.spacing(1),
		alignItems: "center",
		borderRadius: "80px",
	};
});

import { ApolloClient, NormalizedCacheObject, useSubscription } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { LockState } from "gql/graphql";
import { PropsWithChildren, createContext, useContext, useEffect } from "react";
import { batch } from "react-redux";
import { captureApolloError } from "services/external/sentry";
import { setQrCode } from "slices/accessSlice";
import { setDishTypes } from "slices/dishesSlice";
import { setDoorLockState, setDoorState, setLockState } from "slices/doorSlice";
import { selectOrder, setOrder } from "slices/transactionSlice";
import { getFridgeId, getTabletTypeFromUrl } from "utils/browser/localStorage";
import { FRIDGE_SUBSCRIPTION, INITIAL_STATE_QUERY } from "utils/gqlOperations";
import { log } from "utils/logger";
import { deepOmit } from "utils/utils";

import { handleSetInitialData, useInitialStateQuery } from "hooks/device/useInitialStateQuery";
import { useDoorAndLockState } from "hooks/useDoorAndLockState";
import { useMixpanelTrackers } from "hooks/useMixpanelTrackers";
import { useRefetchDishTypesQuery } from "hooks/useRefetchDishTypesQuery";
import { useAppDispatch, useAppSelector } from "hooks/utilsHooks/storeHooks";
import { DoorState, Subscription, TransactionState } from "types";
import { LocalFridgeState } from "types/mainTypes";

export type DoorStateType = {
	state: DoorState | undefined;
	lock: {
		state: LockState | undefined;
		timeout: number | undefined | null;
	};
};

export type DeviceContextType = {
	id: string;
	loading: boolean;
	appConnected: boolean;
	handleRefetchDishTypes: () => Promise<void>;
	door: DoorStateType;
};

type DeviceProviderProps = {
	client: ApolloClient<NormalizedCacheObject> | null;
	appConnected: boolean;
	isSubscriptionBroken: boolean;
	setIsSubscriptionBroken: (newState: boolean) => void;
};

const initialState: DeviceContextType = {
	id: "",
	loading: true,
	appConnected: true,
	handleRefetchDishTypes: () => Promise.resolve(),
	door: {
		state: DoorState.Closed,
		lock: {
			state: LockState.Locked,
			timeout: 0,
		},
	},
};

export const DeviceContext = createContext<DeviceContextType>({
	...initialState,
	appConnected: true,
	loading: true,
});

export const DeviceContextProvider = ({
	children,
	appConnected,
	isSubscriptionBroken,
	setIsSubscriptionBroken,
}: PropsWithChildren<DeviceProviderProps>) => {
	getTabletTypeFromUrl();
	const fridgeId = getFridgeId() ?? "";
	const appDispatch = useAppDispatch();
	const order = useAppSelector(selectOrder);
	const { doors, lock, lockTimeout } = useDoorAndLockState();
	const { trackDoorStateChange } = useMixpanelTrackers();

	const {
		data,
		loading: initialQueryLoading,
		refetch: initialQueryRefetch,
	} = useInitialStateQuery({
		fridgeId,
	});
	const {
		loading: refetchDishTypesLoading,
		handleRefetchDishTypes,
		isDeferredRefetchPending,
	} = useRefetchDishTypesQuery({
		fridgeId,
	});

	useEffect(() => {
		// in case of a deferred refetch wait for order to be finished
		const isOrderActive = order?.state !== TransactionState.Finished;
		if (isDeferredRefetchPending && !isOrderActive) {
			handleRefetchDishTypes();
		}
	}, [isDeferredRefetchPending, order]);

	const { restart } = useSubscription<Subscription>(FRIDGE_SUBSCRIPTION, {
		// If there is an error in the initial state query, we do not connect subscription
		skip: data?.fridge?.error !== null && data?.fridge?.error !== undefined,
		variables: { id: fridgeId },
		// By using onData callback, we handle situation when messages from API
		// are delivered in the same time
		onData: ({ data }) => {
			setIsSubscriptionBroken(false);
			const cleanedData = deepOmit(data.data?.fridge, ["__typename"]) as LocalFridgeState;
			const transaction = cleanedData.transaction;
			const isLastOrderFinished =
				transaction?.orders?.last?.state === TransactionState.Finished;
			const hasDishTypes = cleanedData?.dishes?.types;
			batch(() => {
				const hasDoorStateChange =
					cleanedData.door &&
					cleanedData.door.lock !== null &&
					cleanedData.door.lock?.state;

				if (cleanedData.access?.qrCode?.code) {
					appDispatch(setQrCode(cleanedData.access.qrCode.code));
				}
				if (hasDoorStateChange) {
					if (cleanedData.door.state) {
						trackDoorStateChange(cleanedData.door.state);
					}
					appDispatch(setDoorLockState(cleanedData.door));
				} else if (cleanedData?.door?.state) {
					trackDoorStateChange(cleanedData.door.state);
					appDispatch(setDoorState(cleanedData.door.state));
				} else if (cleanedData?.door?.lock?.state) {
					appDispatch(setLockState(cleanedData.door.lock?.state));
				}
				if (transaction?.orders?.last) {
					// Log each production order subscription event to sentry
					// to debug order check screen issues
					// TODO: Remove when resolved
					if (import.meta.env.MODE === "production") {
						Sentry.withScope(function (scope) {
							scope.setContext("orderData", {
								order: order,
								subscriptionData: cleanedData,
							});
							Sentry.captureEvent({ message: "Order data" });
						});
					}
					appDispatch(setOrder(transaction.orders.last));
				}

				if (hasDishTypes || isLastOrderFinished) {
					// In case of last dish in the fridge being taken out, we receive null
					// in dishes.types array, so we set dishTypes to emptyArray
					appDispatch(
						setDishTypes({
							dishTypes: hasDishTypes ?? [],
							isOrder: isLastOrderFinished,
						}),
					);
				}
			});
		},
	});

	const handleReconnectAfterBrokenSubscription = async (retryCount: number) => {
		if (retryCount > 2) {
			captureApolloError(new Error("Failed to load initial fridge state 3 times"), {
				kind: "Initial_Query_Error_Retries_Limit",
				query: INITIAL_STATE_QUERY.kind,
				variables: { fridgeId: fridgeId },
			});
			return;
		}
		restart();
		try {
			log(`Sending initial query after broken subscription on fridge: ${fridgeId}`, "warn");
			const res = await initialQueryRefetch();
			if (!res.data.fridge) {
				captureApolloError(
					new Error("Initial query error"),
					{
						kind: "Initial_Query_Error",
						query: INITIAL_STATE_QUERY.kind,
						variables: { fridgeId: fridgeId },
					},
					{
						ResponseData: res,
					},
				);
				setTimeout(() => {
					handleReconnectAfterBrokenSubscription(retryCount + 1);
				}, 1000);
			} else {
				log(
					`Setting initial data after broken subscription fridge: ${fridgeId}`,
					"warn",
					res.data.fridge,
				);
				handleSetInitialData(res.data?.fridge, appDispatch, order);
			}
		} catch (err) {
			Sentry.captureException(err);
		}
	};

	useEffect(() => {
		const reconnect = async () => {
			if (isSubscriptionBroken && appConnected && fridgeId) {
				await handleReconnectAfterBrokenSubscription(0);
			}
		};
		reconnect();
	}, [isSubscriptionBroken, appConnected, fridgeId]);

	return (
		<DeviceContext.Provider
			value={{
				id: fridgeId,
				appConnected,
				loading: initialQueryLoading || refetchDishTypesLoading,
				door: {
					state: doors,
					lock: {
						state: lock,
						timeout: lockTimeout,
					},
				},
				handleRefetchDishTypes,
			}}
		>
			{children}
		</DeviceContext.Provider>
	);
};

export const useDeviceContext = () => {
	return useContext(DeviceContext);
};

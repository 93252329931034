import { OrderSummary } from "types/mainTypes";

import {
	DRAWER_CLOSED_HEIGHT,
	DRAWER_TOTAL_HEIGHT,
	ORDER_STEP_1_SIZE_LARGE,
	ORDER_STEP_3_SMALL_ORDER_LIMIT,
	ORDER_STEP_5_SMALL_ORDER_LIMIT,
} from "../../utils/designTokens";

export const getDrawerHeight = (
	open: boolean,
	currentOrderStep: number,
	orderSummary: OrderSummary | null,
) => {
	if (open) {
		const dishCountLimit =
			currentOrderStep === 3
				? ORDER_STEP_3_SMALL_ORDER_LIMIT
				: ORDER_STEP_5_SMALL_ORDER_LIMIT;

		const isFullHeightDrawer =
			currentOrderStep !== 1 &&
			orderSummary &&
			orderSummary.orderDishTypes.length > dishCountLimit;

		const isPartiallyOpenDrawer = currentOrderStep === 1 && open;

		if (isFullHeightDrawer) {
			return "100vh";
		} else if (isPartiallyOpenDrawer) {
			return ORDER_STEP_1_SIZE_LARGE;
		}
		return DRAWER_TOTAL_HEIGHT;
	}
	return DRAWER_CLOSED_HEIGHT;
};

import { AnalyticsContextProps } from "types/mainTypes";

let analyticsInstance: AnalyticsContextProps | null = null;

export const setAnalyticsInstance = (instance: AnalyticsContextProps) => {
	analyticsInstance = instance;
};

export const getAnalyticsInstance = () => {
	if (!analyticsInstance) {
		return {
			track: () => null,
			reset: () => null,
			identify: () => null,
			people: {
				set: () => null,
			},
		};
	}
	return analyticsInstance;
};

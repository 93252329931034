import { FC } from "react";

import CeleryIcon from "assets/allergens/celery.svg";
import CrustaceansIcon from "assets/allergens/crustaceans.svg";
import EggsIcon from "assets/allergens/eggs.svg";
import FishIcon from "assets/allergens/fish.svg";
import LupinIcon from "assets/allergens/lupin.svg";
import MilkIcon from "assets/allergens/milk.svg";
import MolluscsIcon from "assets/allergens/molluscs.svg";
import MustardIcon from "assets/allergens/mustard.svg";
import NutsIcon from "assets/allergens/nuts.svg";
import PeanutsIcon from "assets/allergens/peanuts.svg";
import SesameIcon from "assets/allergens/sesame.svg";
import SoybeanIcon from "assets/allergens/soybean.svg";
import SpicyIcon from "assets/allergens/spicy.svg";
import SulphurDioxideIcon from "assets/allergens/suplhur_dioxide.svg";
import WheatIcon from "assets/allergens/wheat.svg";
import { AllergenType } from "types";

export type Allergen = {
	name: AllergenType;
	icon: FC;
};

export const AllergensMapping = [
	{
		name: AllergenType.Gluten,
		icon: WheatIcon,
	},
	{
		name: AllergenType.Crustaceans,
		icon: CrustaceansIcon,
	},
	{
		name: AllergenType.Eggs,
		icon: EggsIcon,
	},
	{
		name: AllergenType.Fish,
		icon: FishIcon,
	},
	{
		name: AllergenType.Peanuts,
		icon: PeanutsIcon,
	},
	{
		name: AllergenType.Soybeans,
		icon: SoybeanIcon,
	},
	{
		name: AllergenType.Milk,
		icon: MilkIcon,
	},
	{
		name: AllergenType.Nuts,
		icon: NutsIcon,
	},
	{
		name: AllergenType.Celery,
		icon: CeleryIcon,
	},
	{
		name: AllergenType.Mustard,
		icon: MustardIcon,
	},
	{
		name: AllergenType.SesameSeeds,
		icon: SesameIcon,
	},
	{
		name: AllergenType.SulphurDioxide,
		icon: SulphurDioxideIcon,
	},
	{
		name: AllergenType.Lupin,
		icon: LupinIcon,
	},
	{
		name: AllergenType.Molluscs,
		icon: MolluscsIcon,
	},
	{
		name: AllergenType.Spicy,
		icon: SpicyIcon,
	},
];

import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useIdleTimerContext } from "react-idle-timer";
import { selectDishTypes } from "slices/dishesSlice";
import {
	selectCurrentOrderStep,
	selectOrder,
	selectOrderDishes,
	updateCurrentOrderStep,
} from "slices/transactionSlice";

import { SnackbarContent } from "components/global/snackbar/SnackbarContent";
import { OrderStep1 } from "components/orderSteps/OrderStep1";
import { OrderStep2 } from "components/orderSteps/OrderStep2";
import { OrderStep3 } from "components/orderSteps/OrderStep3";
import { OrderStep5 } from "components/orderSteps/OrderStep5";
import { useDeviceContext } from "contexts/DeviceContext";
import { useSnackbarContext } from "contexts/SnackbarContext";
import { DoorState, LockState, TransactionState } from "types";

import { selectHasDoorBeenOpened, setHasDoorBeenOpenedDuringOrder } from "../slices/doorSlice";
import { useOrder } from "./useOrder";
import { useAppDispatch, useAppSelector } from "./utilsHooks/storeHooks";

export const useOrderSteps = ({
	open,
	toggleDrawer,
}: {
	open: boolean;
	toggleDrawer: (newState: boolean) => void;
}) => {
	const dispatch = useAppDispatch();
	const stock = useAppSelector(selectDishTypes);
	const order = useAppSelector(selectOrder);
	const orderDishes = useAppSelector(selectOrderDishes);
	const currentOrderStep = useAppSelector(selectCurrentOrderStep);
	const hasDoorBeenOpened = useAppSelector(selectHasDoorBeenOpened);
	const { finishOrder } = useOrder();
	const { door } = useDeviceContext();
	const { openSnackbarWithContent } = useSnackbarContext();
	const idleTimer = useIdleTimerContext();
	const { lock, state: doors } = door;

	useEffect(() => {
		const isClosedAndLocked = lock.state === LockState.Locked && doors === DoorState.Closed;
		const isClosedAndUnlocked = lock.state === LockState.Unlocked && doors === DoorState.Closed;
		if (!order) {
			dispatch(updateCurrentOrderStep(1));
			dispatch(setHasDoorBeenOpenedDuringOrder(false));

			if (!isClosedAndLocked) {
				Sentry.captureException(new Error("Door opened without order"));
			}
		} else if (isClosedAndUnlocked && order?.inventory?.removed?.length === 0) {
			// If user has not interacted with the tablet, we activate the idle timer,
			// so the lights are turned on
			if (idleTimer.isIdle()) {
				idleTimer.activate();
			}
			dispatch(updateCurrentOrderStep(2));
		} else if (doors === DoorState.Opened && order?.state === TransactionState.Active) {
			// Set the flag to true, so we know that the fridge door has been opened
			dispatch(setHasDoorBeenOpenedDuringOrder(true));
			dispatch(updateCurrentOrderStep(3));
		} else if (order && order.state === TransactionState.StockCheck) {
			// If the user opened the door, we show stock check screen,
			// otherwise we finish the order and return to the first step
			if (!hasDoorBeenOpened) {
				openSnackbarWithContent(
					<SnackbarContent variant="orderNotStarted" user={order?.user} />,
				);
				dispatch(updateCurrentOrderStep(5));
			}
		} else if (order && order.state === TransactionState.Finished) {
			// If the user opened the door, we show order summary screen, the order will have
			// this state even when user did not open the door, in that case, we do not show Order5
			if (currentOrderStep !== 5) {
				dispatch(updateCurrentOrderStep(5));
			}
		}
	}, [order?.state, order?.inventory, orderDishes, stock, doors, lock, hasDoorBeenOpened]);

	switch (currentOrderStep) {
		case 1:
			return <OrderStep1 open={open} toggleDrawer={toggleDrawer} />;
		case 2:
			return <OrderStep2 open={open} toggleDrawer={toggleDrawer} />;
		case 3:
		case 4:
			return <OrderStep3 open={open} stock={stock ?? []} toggleDrawer={toggleDrawer} />;
		case 5:
			return (
				<OrderStep5
					open={open}
					resetOrder={() => {
						finishOrder();
					}}
					toggleDrawer={toggleDrawer}
					doorHasBeenOpened={hasDoorBeenOpened}
				/>
			);
		default:
			return <OrderStep1 open={true} toggleDrawer={toggleDrawer} />;
	}
};
